import React, { useRef,useState, useContext, useEffect } from 'react';
import './DashboardBalance_.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import { useTranslation } from 'react-i18next';
const DashboardBalance = () => {
    const { t } = useTranslation();
    const {Wallet,PNL} = useContext(Degiskenler);
    const [xgcInfo, setXgcInfo] = useState({ balance: 0, price: 0 });
    const [walletComponents, setWalletComponents] = useState([]);
    const totalValue = Wallet.reduce((acc, curr) => {
        return acc + parseFloat(curr.Price);
    }, 0);
    useEffect(() => {
        const sortedWallet = Wallet.sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price)).slice(0, 5);

        const totalValue = Wallet.reduce((acc, curr) => acc + parseFloat(curr.Price), 0);

        const components = sortedWallet.map(item => {
        const percentageOfTotal = (parseFloat(item.Price) / totalValue) * 100;
        
        return (
            <div key={item.coin} className='Dashboard-Balance-content'>
                <div className='Dashboard-Balance-item2'>
                    <div className='Dashboard-Balance-item-Circular'>
                        <CircularProgressbar
                            value={percentageOfTotal}
                            text={item.Balance > 0 ? `${percentageOfTotal.toFixed(2)}%`:'0%'}
                            styles={buildStyles({
                                pathColor: '#1D5EC7',
                                textColor: '#ffffffb6',
                                trailColor: '#1C2132'
                            })}
                        />
                    </div>
                    <div className='balance-Coin'>
                        <label>{parseFloat(item.Balance).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})}</label>
                        <label>{item.coin}</label>
                    </div>
                </div>
            </div>
        );
    });

    setWalletComponents(components);
    }, [Wallet,totalValue]);
    useEffect(() => {
        const xgcData = Wallet.find(coin => coin.coin === 'GBIT');
        if (xgcData) {
            setXgcInfo({ balance: xgcData.Balance, price: xgcData.Price });
        }
    }, [Wallet]);
    
    

    const scrollRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startScrollLeft, setStartScrollLeft] = useState(0);

    const startDragging = (e) => {
        setIsDragging(true);
        setStartX(e.pageX);
        setStartScrollLeft(scrollRef.current.scrollLeft);
        e.preventDefault(); // Varsayılan davranışı engelle
    };
    const stopDragging = () => {
        setIsDragging(false);
    };
    const onDrag = (e) => {
        if (!isDragging) return;
        const x = e.pageX;
        const walk = (x - startX);
        scrollRef.current.scrollLeft = startScrollLeft - walk;
    };

    return (
        <div
            ref={scrollRef}
            onMouseDown={startDragging}
            onMouseUp={stopDragging}
            onMouseLeave={stopDragging}
            onMouseMove={onDrag}
            className="Dashboard-Balance"
        >
            <div className='Dashboard-Balance-Scrool'>
                <div className='Dashboard-Balance-content' >
                    <div className='Dashboard-Balance-item'>
                        <div className="title">{t('EstimatedBalance')}<img src="visible.png" alt="" /></div>
                        <div className="balance">{parseFloat(xgcInfo.balance).toLocaleString(navigator.language,{minimumFractionDigits: 0,maximumFractionDigits: 2})} GBIT <span>≈ {xgcInfo.price.toLocaleString()} USD</span></div>
                        <div className="dotted-line"></div>
                        <div className="pnl">{t('TodaysPnL')} <span className="pnl-value">{PNL.today.toLocaleString()} ({PNL.pnl}%)</span></div>
                    </div>
                </div>
                {walletComponents}
            </div>
        </div>
    );
}

export default DashboardBalance;