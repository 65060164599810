import React from 'react'; 
import { BrowserRouter, Route, Routes } from "react-router-dom";

import './App.css';
import Home from "./Sayfalar/Home/Home";
import Dashboard from "./Sayfalar/Dashboard/Dashboard";
import Privacypolicy from "./Sayfalar/Contracts/privacypolicy";
import Termsconcitions from "./Sayfalar/Contracts/termsconcitions";
import Layout from "./components/Layout/Layout";
import './Context/i18n';
function App() {
  return (
      <div className="App">
        <BrowserRouter> 
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Home/>} />
                    <Route path="/Home" element={<Home />} />
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/privacy-policy" element={<Privacypolicy />} />
                    <Route path="/terms-concitions" element={<Termsconcitions />} />
                </Route>
            </Routes>
        </BrowserRouter>
        
      </div>
   
  );
}

export default App;