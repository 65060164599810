
import React from 'react';
import './CoinIcon_.css';

const coinNames = {
    GBIT: "GameBit",///
    BTC: "Bitcoin",///
    ETH: "Ethereum",
    USDC: "USD Coin",
    USDT: "TetherUS",
    BNB: "BNB",
    XRP: "Ripple",
    DOGE: "Dogecoin",
    ADA: "Cardano",
    TRX: "TRON",
    SHIB: "SHIBA INU",
    DOT: "Polkadot",
    BCH: "Bitcoin Cash",
    LINK: "ChainLink",
    LTC: "Litecoin",
    NEAR: "NEAR Protocol",
    MATIC: "Polygon",
    UNI: "Uniswap",
    PEPE: "Pepe",
    ARB:"Arbitrum",
    FLOKI:"FLOKI",
    XLM:"Stellar",
    BTT:"Bittorrent",
    FIL:"Filecoin",
    ATOM:"Cosmos",
    MKR:"Maker",
    VET:"VeChain",
    FTM:"Fantom",
    MANA:"Decentraland",
  };

const CoinIcon = ({CoinID_, CoinIcon_, CoinName_ }) => {

  const Icon = () => {
    const iconSrc = `/CoinIcons/${CoinID_}.png`;
    const coinName = coinNames[CoinID_] || CoinID_; 
    return (
        <div className="coin-icon">
            {CoinIcon_ && <img src={iconSrc} alt="" />}
            {CoinName_ && <label htmlFor="">{coinName}</label>}
        </div>
    );
  };
  return <Icon />;
};
export default CoinIcon;