import './Navbar-Dashboard_.css';
import React, {useRef, useState,useEffect,useContext} from "react";
import {  Link as RouterLink } from "react-router-dom";
import {Link } from 'react-scroll';
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import { debounce } from 'lodash';
import Select from 'react-select';
import { FaBell,FaWallet } from "react-icons/fa";
import { MdOutlineSettings, MdOutlineLogout } from "react-icons/md";
import Cookies from 'js-cookie';
import LanguageIcon from '../Language/Language';
import { useTranslation } from 'react-i18next';
const Navbar = ({ setPanel }) => {
  const {i18n, t} = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
      };

  const {BackHome,Language,setLanguage,LoginStatus} = useContext(Degiskenler);
  const[MenuDurum,MenuDeger]= useState(false);
  useEffect(() => {
    if (MenuDurum) {
      document.body.style.overflow = 'hidden';
    }  else {
      document.body.style.overflow = 'auto';
    }
  }, [MenuDurum]);

  useEffect(() => {
    if (!MenuDurum) {
      document.body.style.overflow = MenuDurum ? 'hidden' : 'auto';
    }
   
  }, [MenuDurum]);
  const handleChange = selectedOption => {
    setLanguage(selectedOption.value);
    changeLanguage(selectedOption.value);
    localStorage.setItem('Language',selectedOption.value);
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: 'white', // Seçeneklerin yazı rengi
      backgroundColor: state.isSelected ? '#292d34' : state.isFocused ? '#292d34' : null,
      cursor: 'pointer'
    }),
    control: styles => ({
      ...styles,
      width: '100%',
      backgroundColor: '#292d34',
      color: 'white',
      border:'none',
      cursor: 'pointer'
    }),
    input: (styles) => ({
        ...styles,
        color: 'white'
    }),
    container: (base) => ({
        ...base,
        color: 'white',
        width: '100%' // Select bileşeninin genişliği
      }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'white', // Seçilen değerin yazı rengi
      backgroundColor: '#292d34',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: 'white', // Placeholder yazı rengi
      backgroundColor: '#292d34',
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#292d34', // Açılan menünün arka plan rengi
        border: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)',
        width: '100%'
    }),
    menuList: (provided, state) => ({
    ...provided,
    backgroundColor: '#292d34',
    padding: 0,
    border: 'none'
    })
  };
  const options = [
    { value: 'ar', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Arabic'} /></span>) },
    { value: 'bg', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Bulgarian'} /></span>) },
    { value: 'en', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'English'} /></span>) },
    { value: 'zh', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Chinese'} /></span>) },
    { value: 'cs', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Czech'} /></span>) },
    { value: 'nl', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Dutch'} /></span>) },
    { value: 'fr', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'French'} /></span>) },
    { value: 'de', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'German'} /></span>) },
    { value: 'el', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Greek'} /></span>) },
    { value: 'hu', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Hungarian'} /></span>) },
    { value: 'id', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Indonesian'} /></span>) },
    { value: 'it', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Italian'} /></span>) },
    { value: 'ja', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Japanese'} /></span>) },
    { value: 'ko', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Korean'} /></span>) },
    { value: 'pl', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Polish'} /></span>) },
    { value: 'pt', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Portuguese'} /></span>) },
    { value: 'ro', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Romanian'} /></span>) },
    { value: 'ru', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Russian'}/></span> )},
    { value: 'sk', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Slovak'} /></span>) },
    { value: 'es', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Spanish'} /></span>) },
    { value: 'th', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Thai'}/></span>) },
    { value: 'tr', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Turkish'}/></span>) },
    { value: 'vi', label: (<span className='Deposit-CoinIcon-label'> <LanguageIcon LanguageID_={'Vietnamese'}/></span>) },
  ];


  const [isOpen, setIsOpen] = useState(false);
  const toggleContainer = useRef(null);

  const onClickHandler = (action) => {
    switch (action) {
      case 'Profile':
        console.log('Profile Settings');
        break;
      case 'Logout':
        Logout();
        break;
      default:
        console.log('No action');
    }
    setIsOpen(false); // İşlem yapıldıktan sonra menüyü kapat
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (toggleContainer.current && !toggleContainer.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleContainer]);

  const handleButtonClick = debounce((buttonName) => {
    setPanel(buttonName);
}, 350); // 100 milisaniye delay


const Logout = async () => {
  const refreshToken = Cookies.get('refreshToken');
  const token = Cookies.get('accessToken');

  console.log("token:",token);
  console.log("refreshToken:",refreshToken);
  try {
    const requestBody = {
      token: refreshToken
  };

    const response = await fetch('https://game-bit.com/api/Users/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
            
        },
        body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      console.log(`HTTP error! status: ${response.status}`);
      return;
    }
    BackHome();
  } catch (error) {

    console.log('Error fetching wallet data:', error);
  }
};
  return (
      <div className='Dashboard-Panel-Navbar'>
        
        <div className={MenuDurum ? 'Dashboard-Panel-MenuPanel':'Dashboard-Panel-MenuPanelPasif' }>
          <div className="Dashboard-Panel-UstMenu">
            <Link className="Dashboard-Panel-logoMenu" to={"./Home"}>
              <img src="Icon.png" alt="Close" className="Dashboard-Panel-MenuIconBackround"></img>
              <img src="Logo.png" alt="Close" className="Dashboard-Panel-MenuIcon" ></img>
            </Link>
            <div className="Dashboard-Panel-MenuPanelKapat" onClick={()=> MenuDeger(!MenuDurum)}>
              <img src="Close.png" alt="Close"></img>
            </div> 
            <div className='Dashboard-Panel-MenuCizgi'></div>
          </div>
          
          <div className="Dashboard-Panel-AltMenu">
            <div className="Dashboard-Panel-MenuList"  onClick={()=>{MenuDeger(!MenuDurum); handleButtonClick('Wallet');}} >
              <div className='Dashboard-Panel-MenuActiveBar'></div>
              <div className='Dashboard-Panel-MenuText'>{t('Wallet')}</div>
            </div>
            <div className="Dashboard-Panel-MenuList"  onClick={()=> {MenuDeger(!MenuDurum); handleButtonClick('BuySel');}}>
              <div className='Dashboard-Panel-MenuActiveBar'></div>
              <div className='Dashboard-Panel-MenuText'>{t('BuySell')}</div>
            </div>
            <div className="Dashboard-Panel-MenuList"  onClick={()=> {MenuDeger(!MenuDurum); handleButtonClick('Deposit');}}>
              <div className='Dashboard-Panel-MenuActiveBar'></div>
              <div className='Dashboard-Panel-MenuText' >{t('Deposit')}</div>
            </div>
            <div className="Dashboard-Panel-MenuList"  onClick={()=> {MenuDeger(!MenuDurum); handleButtonClick('Withdraw');}}>
              <div className='Dashboard-Panel-MenuActiveBar' ></div>
              <div className='Dashboard-Panel-MenuText'>{t('Withdraw')}</div>
            </div>
            <div className="Dashboard-Panel-MenuList"  onClick={()=> {MenuDeger(!MenuDurum); handleButtonClick('Referance');}}>
              <div className='Dashboard-Panel-MenuActiveBar' ></div>
              <div className='Dashboard-Panel-MenuText'>{t('Referance')}</div>
            </div>
            <div className={LoginStatus ? 'Dashboard-Panel-MenuButtons-Close' : 'Dashboard-Panel-MenuButtons'}>
              <div className='Dashboard-Panel-MenuLogin' onClick={() => {}} >{t('Sign in')}</div>
              <div className='Dashboard-Panel-MenuButtonRegister' onClick={() => {}} >{t('Sign up')}</div>
            </div>
            
            <RouterLink to="./Dashboard" className={!LoginStatus ? 'MenuButtons-Close' : 'MenuDashboard'}>{t('Dashboard')}</RouterLink>
          </div>
        </div>
        <div className='Dashboard-Panel-Center'>
        <RouterLink to="/" className='Dashboard-Panel-Sol'>
          <img src={process.env.PUBLIC_URL + '/Icon.png'} alt="Menu" className="Dashboard-Panel-LogoBoyut"></img>
          <img src={process.env.PUBLIC_URL + '/Logo.png'} alt="Menu" className="Dashboard-Panel-LogoAnim"></img>
          </RouterLink>
          <div className='Dashboard-Panel-Sag'>
            <div className='Dashboard-Panel-Sag-Menu'>
              <div className='Dashboard-Panel-Sag-Menu-language'>
                <Select
                    value={options.find(option => option.value === Language)}
                    onChange={handleChange}
                    options={options}
                    styles={customStyles}
                    placeholder="Select or search a coin..."
                    isSearchable={true}
                    noOptionsMessage={() => "No matching results"}
                />
              </div>
              <div onClick={() => {handleButtonClick('Wallet')}} className='Dashboard-Panel-Sag-Menu-Wallet' ><FaWallet /></div>
              <div onClick={() => setIsOpen(!isOpen)} className='Dashboard-Panel-Sag-Menu-Profile' ref={toggleContainer}>
                <MdOutlineSettings />
                {isOpen && (<div className='Dashboard-Panel-Sag-Menu-Profile-Menu'>
                    <div className='Dashboard-Panel-Sag-Menu-Profile-Menu-content'>
                     
                      <div  className='Dashboard-Panel-Sag-Menu-Profile-Menu-Item' onClick={() => onClickHandler('Logout')}>
                        <MdOutlineLogout />
                        {t('Logout')}
                      </div>
                    </div>
                  </div>
                )}

                </div>
              <div className='Dashboard-Panel-Sag-Menu-Info'><FaBell /> <span>0</span></div>
            </div>
            <div className='Dashboard-Panel-MenuMobile' onClick={()=> MenuDeger(!MenuDurum)}>
              <img src="Menu.png" alt="Menu"></img>
            </div>
          </div>
        </div>
       
      </div>
  )
}

export default Navbar

/*
import {FaUser } from "react-icons/fa";
 <div className='Dashboard-Panel-Sag-Menu-Profile-Menu-Item' onClick={() => onClickHandler('Profile')} >
                        <FaUser />
                        Profile
                      </div>
*/