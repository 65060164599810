import './Footer_.css';
import { useLocation } from "react-router-dom";
import React, { useEffect,useState} from "react";
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const { t } = useTranslation();
  const [SubscribeEmail, setSubscribeEmail] = useState('');
  const [Eror, setEror] = useState(false);
  const [successful, setsuccessful] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const isValidEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const fetchsubscribe = async () => {
    if (!isValidEmail(SubscribeEmail)) {
      setEror(true);
      alert("Email Invalide");
      return;
    }
    const requestBody = {email:SubscribeEmail };
    try {
      const response = await fetch('https://game-bit.com/api/Users/Subscribe', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        setEror(true);
        return;
      }
      alert("Successful");
      setEror(false);
      setsuccessful(true);
      setSubscribeEmail("");
    } catch (error) {
      setEror(true);
      console.error('Error fetching wallet data:', error);
    }
  };
useEffect(() => {
  if (Eror || successful) {
    const timer = setTimeout(() => {
      setEror(false);
      setsuccessful(false);
    }, 2000);
    return () => clearTimeout(timer);
  }
}, [Eror,successful]);
  return (
    <div>
      
      <div className='Footer'>
      
       <div className='F-Alan'>
          <div className='F-Ust'>
            <div className='F-UstSol'>
              <div className='F-LogoAlani'>
                <img src="Logo.png" alt="Follow" />
                <img src="PcoinIsim.png" alt="PcoinIsim" />
              </div>
              <p>{t('Footer-logo-info')}</p>
            </div>
            <div className='F-UstSag'>
              <div className='F-EmailBaslik'>{t('Subscribe-Our-NewSietter')}</div>
              <div className='F-EmailAciklama'>{t('Footer-logo-info2')}</div>
              <div className='F-EmailAlani'>
                <div className={Eror?'F-Email-red': (successful?'F-Email-Green':'F-Email')}>
                  <input  
                    type="text"
                    id="fname"
                    value={SubscribeEmail}
                    onChange={(e) => setSubscribeEmail(e.target.value)}
                    placeholder={t('Enter Your Email Address')}
                    onFocus={(e) => {setEror(false);setsuccessful(false);}}
                  />
                </div>
                <div className='F-EmailButton' onClick={() => fetchsubscribe()}>
                  <img src="Mektup.png" alt="Mektup" />
                  <p>{t('SUBSCRIBE')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='F-Orta'>
            <div className='F-OrtaItem'>
              <div className='F-OrtaItemBaslik'>
                <h2>{t('Download')}</h2>
                <div className='F-AltCizgi'></div>
              </div>
              <div className='F-OrtaItemLinkBolgesi'>
                <a className="F-OrtaItemLink" target="_blank" href="/WhitePaper/GAMEBIT(GBIT).pdf">
                  <h6>WHITEPAPER</h6>
                  <span className="F-OrtaItemLinkLine"><h6>»</h6></span>
                </a>
                
              </div>
            </div>
            <div className='F-OrtaItem'>
              <div className='F-OrtaItemBaslik'>
                <h2>{t('ssl')}</h2>
                <div className='F-AltCizgi'></div>
              </div>
              <div className='F-OrtaItemBank'>
          
                <img src="Paypal.png" alt="Paypal" />
                <img src="Visa.png" alt="Visa" />
                <img src="MasterCard.png" alt="MasterCard" />
                <img src="Skrill.png" alt="Skrill" />
                <img src="Stripe.png" alt="Stripe" />
                <img src="Cirrus.png" alt="Cirrus" />
                <img src="Discover.png" alt="Discover" />
                <img src="MetaMask.png" alt="MetaMask" />
                <img src="Trust Wallet.png" alt="Trust Wallet" />
                <img src="CoinBase Wallet.png" alt="CoinBase Wallet" />
                <img src="MyEtherWallet.png" alt="CoinBase Wallet" />
              </div>
              
            </div>
            <div className='F-OrtaItem'>
              <div className='F-OrtaItemBaslik'>
                <h2>{t('FollowUs')}</h2>
                <div className='F-AltCizgi'></div>
              </div>
              
              <div className='F-OrtaItemFollow'>
                <a className='F-OrtaItemFollowItem' target="_blank" href="https://x.com/GAMEBIT_COIN" rel="noopener noreferrer">
                  <img src="Twitter.png" alt="Twitter" />
                </a>
                <a className='F-OrtaItemFollowItem' target="_blank" href="https://discord.gg/u5T58ds6" rel="noopener noreferrer">
                  <img src="discord.png" alt="discord" />
                </a>
                <a className='F-OrtaItemFollowItem' target="_blank" href="https://t.me/GBITOfficial" rel="noopener noreferrer">
                  <img src="telegram.png" alt="telegram" />
                </a>
              </div>

            </div>
          </div>
          <div className='F-Cizgi'></div>
          <div className='F-Alt'>
            <span>
              <h6>{t('All Rights Roserved')}</h6>
            </span>
            <span className='F-AltSag'>
                
            </span>
          </div>
       </div>
      </div>
    </div>
  )
}

export default Footer