import React from 'react';
import './Language_.css';


const Language = ({LanguageID_}) => {

  const Icon = () => {
    const iconSrc = `/LanguageIcons/${LanguageID_}.png`;
    return (
        <div className="coin-icon">

            {<img className="coin-icon-img" src={iconSrc} alt="" />}
            {<label htmlFor="">{LanguageID_}</label>}
        </div>
    );
  };
  return <Icon />;
};
export default Language;