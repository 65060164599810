import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Çevirilerinizi içeren JSON dosyalarını import edin
import translationAR from './Languages/ar.json';
import translationBG from './Languages/bg.json';
import translationCS from './Languages/cs.json';
import translationDE from './Languages/de.json';
import translationEL from './Languages/el.json';
import translationEN from './Languages/en.json';
import translationES from './Languages/es.json';
import translationFR from './Languages/fr.json';
import translationHU from './Languages/hu.json';
import translationID from './Languages/id.json';
import translationIT from './Languages/it.json';
import translationJA from './Languages/ja.json';
import translationKO from './Languages/ko.json';
import translationNL from './Languages/nl.json';
import translationPL from './Languages/pl.json';
import translationPT from './Languages/pt.json';
import translationRO from './Languages/ro.json';
import translationRU from './Languages/ru.json';
import translationSK from './Languages/sk.json';
import translationTH from './Languages/th.json';
import translationTR from './Languages/tr.json';
import translationVI from './Languages/vi.json';
import translationZH from './Languages/zh.json';

// i18next için dil kaynaklarını tanımlayın
const resources = {
  ar: {
    translation: translationAR
  },
  bg: {
    translation: translationBG
  },
  cs: {
    translation: translationCS
  },
  de: {
    translation: translationDE
  },
  el: {
    translation: translationEL
  },
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  },
  hu: {
    translation: translationHU
  },
  id: {
    translation: translationID
  },
  it: {
    translation: translationIT
  },
  ja: {
    translation: translationJA
  },
  ko: {
    translation: translationKO
  },
  nl: {
    translation: translationNL
  },
  pl: {
    translation: translationPL
  },
  pt: {
    translation: translationPT
  },
  ro: {
    translation: translationRO
  },
  ru: {
    translation: translationRU
  },
  sk: {
    translation: translationSK
  },
  th: {
    translation: translationTH
  },
  tr: {
    translation: translationTR
  },
  vi: {
    translation: translationVI
  },
  zh: {
    translation: translationZH
  }
};

i18n
  .use(initReactI18next) // react-i18next geçişini başlat
  .init({
    resources,
    lng: localStorage.getItem('Language'), // varsayılan dil
    fallbackLng: 'en', // bulunamayan diller için geri dönüş

    interpolation: {
      escapeValue: false // React zaten güvenli.
    }
  });

export default i18n;