import React, {} from 'react';
import './DepositDetails_.css'; // CSS dosyası bağlantısı
import { useTranslation } from 'react-i18next';
function DepositDetails({ details, onClose }) {
    const { t } = useTranslation();
    return (
        <div className='deposit-details-Panel'>
            <div className="deposit-details">
                <div className="deposit-header">
                    <h2>{t('DepositDetails')}</h2>
                    <button onClick={() => onClose()} className="close-button">✖</button>
                </div>
                <div className="detail-item">
                    <strong>{t('Status')}:</strong> <span>{details.status}</span>
                </div>
                <div className="detail-item">
                    <strong>{t('Date')}:</strong> <span>{details.date}</span>
                </div>
                <div className="detail-item">
                    <strong>{t('Coin')}:</strong> <span>{details.coin}</span>
                </div>
                <div className="detail-item">
                    <strong>{t('Depositamount')}:</strong> <span>{details.depositAmount}</span>
                </div>
                <div className="detail-item">
                    <strong>{t('DepositNetwork')}:</strong> <span>{details.depositNetwork}</span>
                </div>
                <div className="detail-item">
                    <strong>{t('Deposit-receiving-address')}:</strong> <span>{details.receiverAddress}</span>
                </div>
                
            </div>
        </div>
    );
}

export default DepositDetails;/*
<div className="detail-item">
                    <strong>TxID/TxHash:</strong> <span>{details.txId}</span>
                </div>*/