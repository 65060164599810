import React, {useState, useContext,useEffect, useRef} from 'react';
import './DashboardBuyPanel_.css';
import Cookies from 'js-cookie';
import Select from 'react-select';
import { Degiskenler } from '../../Context/GlobalDegiskenler';

import CoinIcon from '../CoinIcon/CoinIcon';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const DashboardBuyPanel = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const {refreshToken,BackHome, SelectedCoin,setSelectedCoin,Wallet, setWallet} = useContext(Degiskenler);
    const [TotalBalance, setTotalBalance] = useState(0);
    const [CoinID, setCoinID] = useState("USDT");
    const [CoinBalance, setCoinBalance] = useState(0);
    const [CoinBalancePrice, setCoinBalancePrice] = useState(0);
    const [CoinMarketPrice, setCoinMarketPrice] = useState(1);

    const [CoinAmountBuy, setCoinAmountBuy] = useState('1');
    const [CoinTotalBuy, setCoinTotalBuy] = useState("0.0005");
    const [insufficient, setinsufficient] = useState(false);

    const [percentage, setPercentage] = useState(0);

    const sliderRef = useRef(null);
    const labelRef = useRef(null); 

    const [ErorLog, setErorLog] = useState("");

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: 'white', // Seçeneklerin yazı rengi
            backgroundColor: state.isSelected ? '#292d34' : state.isFocused ? '#292d34' : null,
            cursor: 'pointer'
        }),
        control: styles => ({
            ...styles,
            width: '100%',
            backgroundColor: '#292d34',
            color: 'white',
            border:'none',
            cursor: 'pointer',
            padding: '2px 0px'
        }),
        input: (styles) => ({
            ...styles,
            color: 'white',
            margin: 0,
            padding: '0px',
            width: '100%',
            height: '20px'
        }),
        container: (base) => ({
            ...base,
            color: 'white',
            width: '100%' // Select bileşeninin genişliği
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'white', // Seçilen değerin yazı rengi
            backgroundColor: '#292d34',
            padding: '0px 0px', // singleValue alanının padding'ini genişletin
            width: '100%'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'white', // Placeholder yazı rengi
            backgroundColor: '#292d34'
            
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#292d34', // Açılan menünün arka plan rengi
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)',
            width: '100%'
            
        }),
        menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#292d34',
        padding: 0,
        border: 'none',
        "::-webkit-scrollbar": {
            width: "0px"
        },
        
        }),
        indicatorSeparator: () => ({
            display: 'none',
            padding: 0
        })
    };
    const customComponents = {
        DropdownIndicator: null,
    };
    const options = [
        { value: 'USDT', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"USDT"} CoinIcon_={true} CoinName_= {true}/><strong>USDT</strong></span>) },
        { value: 'BTC', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"BTC"} CoinIcon_={true} CoinName_= {true}/><strong>BTC</strong></span>) },
        { value: 'ETH', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"ETH"} CoinIcon_={true} CoinName_= {true}/><strong>ETH</strong></span>) },
        { value: 'USDC', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"USDC"} CoinIcon_={true} CoinName_= {true}/><strong>USDC</strong></span>) },
        { value: 'BNB', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"BNB"} CoinIcon_={true} CoinName_= {true}/><strong>BNB</strong></span>) },
        { value: 'XRP', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"XRP"} CoinIcon_={true} CoinName_= {true}/><strong>XRP</strong></span>) },
        { value: 'DOGE', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"DOGE"} CoinIcon_={true} CoinName_= {true}/><strong>DOGE</strong></span>) },
        { value: 'ADA', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"ADA"} CoinIcon_={true} CoinName_= {true}/><strong>ADA</strong></span>) },
        { value: 'TRX', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"TRX"} CoinIcon_={true} CoinName_= {true}/><strong>TRX</strong></span>) },
        { value: 'SHIB', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"SHIB"} CoinIcon_={true} CoinName_= {true}/><strong>SHIB</strong></span>) },
        { value: 'DOT', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"DOT"} CoinIcon_={true} CoinName_= {true}/><strong>DOT</strong></span>) },
        { value: 'BCH', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"BCH"} CoinIcon_={true} CoinName_= {true}/><strong>BCH</strong></span>) },
        { value: 'LINK', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"LINK"} CoinIcon_={true} CoinName_= {true}/><strong>LINK</strong></span>) },
        { value: 'LTC', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"LTC"} CoinIcon_={true} CoinName_= {true}/><strong>LTC</strong></span>) },
        { value: 'NEAR', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"NEAR"} CoinIcon_={true} CoinName_= {true}/><strong>NEAR</strong></span>) },
        { value: 'MATIC', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"MATIC"} CoinIcon_={true} CoinName_= {true}/><strong>MATIC</strong></span>) },
        { value: 'UNI', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"UNI"} CoinIcon_={true} CoinName_= {true}/><strong>UNI</strong></span>) },
        { value: 'PEPE', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"PEPE"} CoinIcon_={true} CoinName_= {true}/><strong>PEPE</strong></span> )},
        { value: 'ARB', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"ARB"} CoinIcon_={true} CoinName_= {true}/><strong>ARB</strong></span>) },
        { value: 'FLOKI', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"FLOKI"} CoinIcon_={true} CoinName_= {true}/><strong>FLOKI</strong></span>) },
        { value: 'XLM', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"XLM"} CoinIcon_={true} CoinName_= {true}/><strong>XLM</strong></span>) },
        { value: 'BTT', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"BTT"} CoinIcon_={true} CoinName_= {true}/><strong>BTT</strong></span>) },
        { value: 'FIL', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"FIL"} CoinIcon_={true} CoinName_= {true}/><strong>FIL</strong></span>) },
        { value: 'ATOM', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"ATOM"} CoinIcon_={true} CoinName_= {true}/><strong>ATOM</strong></span>) },
        { value: 'MKR', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"MKR"} CoinIcon_={true} CoinName_= {true}/><strong>MKR</strong></span>) },
        { value: 'VET', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"VET"} CoinIcon_={true} CoinName_= {true}/><strong>VET</strong></span>) },
        { value: 'FTM', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"FTM"} CoinIcon_={true} CoinName_= {true}/><strong>FTM</strong></span>) },
        { value: 'MANA', label: (<span className='DashboardBuy-CoinIcon'> <CoinIcon CoinID_={"MANA"} CoinIcon_={true} CoinName_= {false}/><p>MANA</p><strong>MANA</strong></span>) },
    ];

    const handleChange = selectedOption => {
        setSelectedCoin(selectedOption.value);
        fetchWalletData(selectedOption.value);
    };

    const getDecimalSeparator = () => {
        const numberWithDecimalSeparator = 1.1;
        return Intl.NumberFormat(navigator.language)
          .formatToParts(numberWithDecimalSeparator)
          .find(part => part.type === 'decimal').value;
      };
    const handleSliderChange  = (event) => {
        const newPercentage = event.target.value;
        setPercentage(newPercentage);
        calculateXGCRequired(newPercentage/100);
    };
    const handleSliderScrool  = () => {
        if (sliderRef.current && labelRef.current) {
            const slider = sliderRef.current;
            const label = labelRef.current;
            const thumbWidth = 25;
            const sliderWidth = slider.offsetWidth;
            const thumbLeft = (percentage / 100) * (sliderWidth - thumbWidth) + (thumbWidth / 2);
            
            label.style.left = `${thumbLeft - (label.offsetWidth / 2)}px`;
        }
    };
    const handleChangeAmount = (event) => {
        let { value } = event.target;
        const decimalSeparator = getDecimalSeparator();
        const regex = new RegExp(`^[0-9]*(${decimalSeparator})?[0-9]*$`);
        if (!regex.test(value)) {
          value = value.replace(new RegExp(`[^0-9${decimalSeparator}]`, 'g'), '');
          if (value.split(decimalSeparator).length > 2) {
            const parts = value.split(decimalSeparator);
            value = parts[0] + decimalSeparator + parts[1].replace(new RegExp(`${decimalSeparator}`, 'g'), '');
          }
        }
        setCoinAmountBuy(value);
        calculateRequired(value);
        
    };
    const calculateRequired = (xgcAmount) => {
        const amount = parseFloat(xgcAmount.toString().replace(',', '.'));
       
        const totalUsdt = amount * 0.0005;
        let required = totalUsdt / CoinMarketPrice;
        setPercentage(((required/CoinBalance)*100).toFixed(11));
        setCoinTotalBuy((required).toFixed(11));
    };
    const calculateXGCRequired = (percentage_) => {
        const coinAmount = CoinBalance * percentage_;
        const totalUsdt = coinAmount * CoinMarketPrice;
        let requiredXgc = totalUsdt / 0.0005;
        const preciseRequiredXgc = requiredXgc.toFixed(8);
        setCoinTotalBuy((requiredXgc * (0.0005 / CoinMarketPrice)).toFixed(11));
        setCoinAmountBuy(parseFloat(preciseRequiredXgc).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20,useGrouping: false}));
    };
    function MaxBuyAmount() {
        let result = parseFloat((CoinBalancePrice / 0.0005));
        const preciseresult = result.toFixed(8); // 8 basamak hassasiyet
        return parseFloat(preciseresult).toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 20
        });
    }
    const fetchWalletData = async (CoinName) => {
        let Status= await refreshToken();
        if(Status){
            const token = Cookies.get('accessToken');
            const requestBody = {coin: CoinName};
            try {
                const response = await fetch('https://game-bit.com/api/Users/CheckBalance', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(requestBody)
                });
        
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
        
                const data = await response.json();
                setCoinID(CoinName);//adı
                setCoinBalance(data.balance);// AVBL
                setCoinBalancePrice(data.balancePrice);// AVBL
                setCoinMarketPrice(data.price);//Price
                setCoinAmountBuy((1).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20,useGrouping: false}).toString());
                setWallet(currentWallet => {
                    return currentWallet.map(coin => {
                        if (coin.coin === CoinName) {
                            return { ...coin, Balance: data.balance, Price: data.balancePrice };
                        }
                        return coin;
                    });
                });
                console.log('CheckBalance Data:', data);
            } catch (error) {
                console.error('Error fetching wallet data:', error);
            }
        }
    };
    const fetchBuy = async () => {
        let Status= await refreshToken();
        if(Status){
            const token = Cookies.get('accessToken');
            const requestBody = {
                coin: CoinID,
                amount:CoinAmountBuy,
                total:CoinTotalBuy
            };
            try {
                const response = await fetch('https://game-bit.com/api/Users/Buy', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(requestBody)
                });
        
                if (!response.ok) {
                    BackHome();
                    console.log(`HTTP error! status: ${response.status}`);
                    return;
                }
        
                const data = await response.json();
                if(data.info === "successful")
                {
                    setCoinBalance(data.balance);
                    setCoinMarketPrice(data.marketPrice);
                    setCoinBalancePrice(data.balancePrice);
                    calculateXGCRequired(0);
                    setPercentage(0);
                    setCoinAmountBuy((0).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20,useGrouping: false}).toString());
                    setWallet(currentWallet => {
                        return currentWallet.map(coin => {
                            if (coin.coin === CoinID) {
                                return { ...coin, Balance: data.balance, Price: data.balancePrice };
                            }
                            if (coin.coin === "GBIT") {
                                return { ...coin, Balance: data.gbit, Price: data.gbitPrice };
                            }
                            return coin;
                        });
                    });
                }
                if(data.info === "insufficient"){
                    setCoinBalance(data.balance);
                    setCoinMarketPrice(data.marketPrice);
                    setCoinBalancePrice(data.balancePrice);
                    setErorLog("Insufficient Funds");
                }
                if(data.info === "price protection"){
                    setCoinBalance(data.balance);
                    setCoinMarketPrice(data.marketPrice);
                    setCoinBalancePrice(data.balancePrice);
                    setErorLog("Price Protection");
                }
                if(data.info === "eror"){
                    setErorLog("eror");
                }
                if(data.info === "coin not found"){
                    setErorLog("Coin not found");
                }
                console.log('Buy Data:', data);
            } catch (error) {
                BackHome();
                console.error('Error fetching wallet data:', error);
            }
        }
    };
    useEffect(() => {
        fetchWalletData(SelectedCoin);
    }, [location]);
    useEffect(() => {
        
      /*  const amount = parseFloat(CoinAmountBuy.toString().replace(',', '.'));
        if(amount>1){
            let Balance=CoinBalance.toFixed(8);
            if (amount > 1) {Balance -= Balance * 0.01;}
            let result = parseFloat((amount * (0.0005 / CoinMarketPrice)));
            if(Balance !== 0 && amount > 0){
                if(result <= Balance){
                    setinsufficient(false);
                }
                else{
                    setinsufficient(true);
                }
            }
        }*/

        const amount = parseFloat(CoinAmountBuy.toString().replace(',', '.'));
        if(amount>1){
            let Balance=CoinBalance;
            let TotalBuy=parseFloat(CoinTotalBuy.toString().replace(',', '.'));;
            if(Balance !== 0 && amount > 0){
                if(TotalBuy <= Balance){
                    setinsufficient(false);
                }
                else{
                    setinsufficient(true);
                }
            }
        }
    }, [CoinAmountBuy,CoinBalance]);
    useEffect(() => {
        if (CoinMarketPrice > 0) {
            calculateRequired(1);
            setPercentage(0);
        }
    }, [CoinMarketPrice]);
    useEffect(() => {
        handleSliderScrool();
    }, [percentage]);
    useEffect(() => {
        const total = Wallet.reduce((acc, coin) => {
            return acc + parseFloat(coin.Price);
        }, 0);
        setTotalBalance(total);
    }, [Wallet]);
    useEffect(() => {
        if (ErorLog !== "") {
          const timer = setTimeout(() => {
            setErorLog("");
          }, 5000);
          return () => clearTimeout(timer);
        }
      }, [ErorLog]);
    return (
        <div className='Dashboard-Profile-Content'>
            <div className='Dashboard-Profile-Panel'>
                <div className='Dashboard-Profile'>
                    <img src="user.png" alt="" />
                </div>
                <label className='Dashboard-Name' htmlFor="">Total Balance</label>
                <label className='Dashboard-Money' htmlFor="">${parseFloat(TotalBalance.toFixed(2)).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 8})}</label>
            </div>
            <div className='Dashboard-Buy-Panel'>
                <div className='Dashboard-BuySel'>
                    <div className='Dashboard-BuySel-items1'>{t('BUY')}</div>
                    <div className='Dashboard-BuySel-items2'>{t('SELL')}</div>
                </div>
                <div className='Dashboard-CoinSelect'>
                    <div className='Dashboard-CoinSelect-Items1'>
                        <Select
                            value={options.find(option => option.value === SelectedCoin)}
                            onChange={handleChange}
                            options={options}
                            styles={customStyles}
                            components={customComponents}
                            placeholder="Select or search a coin..."
                            isSearchable={true}
                            noOptionsMessage={() => "No matching results"}
                            loadingMessage={() => "Loading..."}
                        />
                        
                    </div>
                    <img src="left-arrow 3.png" alt="" />

                    <div className='Dashboard-CoinSelect-Items2'>
                        <img className='Dashboard-CoinSelect-Items-Logo' src="PGC.png" alt="" />
                        <label className='Dashboard-CoinSelect-Items-Name' htmlFor="">GameBit</label>
                        <label className='Dashboard-CoinSelect-Items-NameID' htmlFor=""><strong>GBIT</strong></label>
                    </div>
                    <div></div>
                </div>
                <div className='Dashboard-MarketLimit'>
                    <label htmlFor="">{t('Limit')}</label>
                    <label htmlFor="">{t('Market')}
                        <div className='Dashboard-MarketLimit-Line-Color'></div>

                    </label>
                </div>
                <div className='Dashboard-MarketLimit-Line'>
                </div>
                <div className='Dashboard-Avbl'>
                    <label htmlFor="">{t('Avbl')}</label>
                    <label htmlFor="">{parseFloat(CoinBalance.toFixed(11)).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} {CoinID}</label>
                </div>
                <div className='Dashboard-Input'>
                    <div className="input-group">
                        <label htmlFor="amount">Price</label>
                        <input type="text" value={parseFloat(CoinMarketPrice).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} readOnly />
                        <span className="currency">{CoinID}</span>
                    </div>
                    <div className={insufficient?"input-group-Red":"input-group"}>
                        <label htmlFor="price">{t('Amount')}</label>
                        <input
                            type="text"
                            value={CoinAmountBuy}
                            onChange={handleChangeAmount}
                        />

                        <span className="currency">GBIT</span>
                    </div>
                    
                    <div className="slider-container">
                        <input
                            ref={sliderRef}
                            type="range"
                            min="0"
                            max="100"
                            value={percentage}
                            className="custom-slider"
                            onChange={handleSliderChange}
                            onMouseEnter={handleSliderScrool}
                        />
                        <div ref={labelRef} className="tooltip">
                            {percentage}%
                            </div>
                    </div>
                    <div className="buttons">
                        <button onClick={() => {calculateXGCRequired(0.25);setPercentage(25)}}>25%</button>
                        <button onClick={() => {calculateXGCRequired(0.50);setPercentage(50)}}>50%</button>
                        <button onClick={() => {calculateXGCRequired(0.75);setPercentage(75)}}>75%</button>
                        <button onClick={() => {calculateXGCRequired(1);setPercentage(100)}}>100%</button>
                    </div>
                    <div className="field">
                        
                        <div className="input-group">
                        <label htmlFor="total">{t('Total')}</label>
                        <input type="text" id="total" value={parseFloat(CoinTotalBuy).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} readOnly />
                        <span className="currency">{CoinID}</span>
                        </div>
                    </div>
                    <div className="info">
                        <p>{t('MaxBuy')} <span className="highlight">{MaxBuyAmount()} GBIT</span></p>
                        <p>{t('Fee')} 0.00%</p>
                    </div>
                    {ErorLog!=="" && <label className='BuyButton-Info'><span>{t('Eror')}: </span>{ErorLog}</label>}
                    <div className='BuyButton-Wait'></div>
                    <button className="buy-button" onClick={() => fetchBuy()}>{t('Buy')} GBIT</button>
                </div>
            </div>
        </div>
    );
}

export default DashboardBuyPanel;