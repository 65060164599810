import './ReferanceList_.css';
import React, { useState,useEffect } from 'react';
import {FaSortDown, FaSortUp  } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
function ReferanceList({List}) {
  const {t} = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  const [FriendSort, setFriendSort] = useState(0);
  const [ReferralSort, setReferralSort] = useState(0);
  const [TradeSort, setTradeSort] = useState(0);
  const [DateSort, setDateSort] = useState(0);
  const toggleSort = (Name) => {
    if(Name === 'Friend')
    {
      setFriendSort((current) => (current + 1) % 3);
      setReferralSort(0);
      setTradeSort(0);
      setDateSort(0);
    }
    if(Name === 'Referral')
    {
      setReferralSort((current) => (current + 1) % 3);
      setFriendSort(0);
      setTradeSort(0);
      setDateSort(0);
    }
    if(Name === 'Trade')
    {
      setTradeSort((current) => (current + 1) % 3);
      setReferralSort(0);
      setFriendSort(0);
      setDateSort(0);
    }
    if(Name === 'Date')
    {
      setDateSort((current) => (current + 1) % 3);
      setReferralSort(0);
      setTradeSort(0);
      setFriendSort(0);
    }
  };
  const filteredTransactions = List.filter(transaction =>
    transaction.friendID.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transaction.earned.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transaction.amount.toLowerCase().includes(searchTerm.toLowerCase())||
    formatDate(transaction.date).toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortTransactions = (List) => {
    if (FriendSort !== 0) {
      List.sort((a, b) => {
        let comparison = b.friendID.localeCompare(a.friendID);
        if (FriendSort === 2) comparison *= -1; // Reverse for descending
        return comparison;
      });
    } 
    else if (ReferralSort !== 0) {
      List.sort((a, b) => {
        let comparison = b.earned.localeCompare(a.earned);
        if (ReferralSort === 2) comparison *= -1;
        return comparison;
      });
    } 
    else if (TradeSort !== 0) {
      List.sort((a, b) => {
        let comparison = parseFloat(a.amount) - parseFloat(b.amount);
        if (TradeSort === 2) comparison *= -1;
        return comparison;
      });
    } 
    else if (DateSort !== 0) {
      List.sort((a, b) => {
        let comparison = parseFloat(a.date) - parseFloat(b.date);
        if (DateSort === 2) comparison *= -1;
        return comparison;
      });
    }
    return List; // Return the sorted array
  };
  useEffect(() => {
    setSearchTerm('');
  }, []);


  const renderTableHeaders = () => {
    const sortedTransactions = sortTransactions([...filteredTransactions]);
    return sortedTransactions.length > 0 && (
      <thead>
        <tr>
          <th onClick={() => toggleSort('Friend')}>
            <div className="Referance-List-th-flex">
            {t('Friends User ID')}
              <span className='Referance-List-table-header-Arrow' >
                  <div><FaSortUp className={ FriendSort === 1 ? 'Referance-List-sort-icon-inactive' : FriendSort === 2 ? 'Referance-List-sort-icon-active' : 'Referance-List-sort-icon-active'}/></div>
                  <div><FaSortDown className={FriendSort === 1 ? 'Referance-List-sort-icon-active' : FriendSort === 2 ? 'Referance-List-sort-icon-inactive' : 'Referance-List-sort-icon-active'}/></div>
              </span>
            </div>
          </th>
          <th onClick={() => toggleSort('Referral')}>
            <div className="Referance-List-th-flex">
            {t('Referral Bonus Earned')}
              <span className='Referance-List-table-header-Arrow' >
                  <div><FaSortUp className={ ReferralSort === 1 ? 'Referance-List-sort-icon-inactive' : ReferralSort === 2 ? 'Referance-List-sort-icon-active' : 'Referance-List-sort-icon-active'}/></div>
                  <div><FaSortDown className={ReferralSort === 1 ? 'Referance-List-sort-icon-active' : ReferralSort === 2 ? 'Referance-List-sort-icon-inactive' : 'Referance-List-sort-icon-active'}/></div>
              </span>
            </div>
          </th>
          <th onClick={() => toggleSort('Trade')}>
            <div className="Referance-List-th-flex">
            {t('Trade Initiated')}
              <span className='Referance-List-table-header-Arrow' >
                  <div><FaSortUp className={ TradeSort === 1 ? 'Referance-List-sort-icon-inactive' : TradeSort === 2 ? 'Referance-List-sort-icon-active' : 'Referance-List-sort-icon-active'}/></div>
                  <div><FaSortDown className={TradeSort === 1 ? 'Referance-List-sort-icon-active' : TradeSort === 2 ? 'Referance-List-sort-icon-inactive' : 'Referance-List-sort-icon-active'}/></div>
              </span>
            </div>
          </th>
          <th onClick={() => toggleSort('Date')}>
            <div className="Referance-List-th-flex">
            {t('Date')}
              <span className='Referance-List-table-header-Arrow' >
                  <div><FaSortUp className={ DateSort === 1 ? 'Referance-List-sort-icon-inactive' : DateSort === 2 ? 'Referance-List-sort-icon-active' : 'Referance-List-sort-icon-active'}/></div>
                  <div><FaSortDown className={DateSort === 1 ? 'Referance-List-sort-icon-active' : DateSort === 2 ? 'Referance-List-sort-icon-inactive' : 'Referance-List-sort-icon-active'}/></div>
              </span>
            </div>
          </th>
        </tr>
      </thead>
    );
  };
  const renderTableRows = () => {
   
    const sortedTransactions = sortTransactions([...filteredTransactions]);
    return sortedTransactions.length > 0 ? (
      sortedTransactions.map((List, index) => (
        <tr key={index}>
          <td>{List.friendID}</td>
          <td>{List.earned} GBIT</td>
          <td>{List.amount} GBIT</td>
          <td>{formatDate(List.date)}</td>
        </tr>
      ))
    ) : (
      <div className="no-data">{t('No data available')} </div>
    );
  };
  return (
    <div className="Referance-activities">
      <div className='Referance-table-content'>
        <table>
          {renderTableHeaders()}
          <tbody>
            {renderTableRows()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReferanceList;