import './DepositRecent_.css';
import React, { useState, useEffect} from 'react';
import { IoMdDocument } from "react-icons/io";
import DepositDetails from './DepositDetails';
import { useTranslation } from 'react-i18next';
function RecentActivities({ History }) {
  const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [timeFilter, setTimeFilter] = useState('All');
    
    const [showDetails, setShowDetails] = useState(false);
    const DetailsClose = () => {
      setShowDetails(false);
    };
    const [details, setDetails] = useState({
      status: '',
      coin: '',
      depositAmount: '',
      date: '', 
      depositNetwork: '',
      receiverAddress: '',
      txId: ''
    });
    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
    };
    const handleTimeFilterChange = (event) => {
      setTimeFilter(event.target.value);
    };
    function formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    }

    const filteredTransactions = History.filter(transaction => {
      const transactionDate = new Date(transaction.time);
      const now = new Date();
      const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));
      const sixtyDaysAgo = new Date(now.setDate(now.getDate() - 60));
      const ninetyDaysAgo = new Date(now.setDate(now.getDate() - 90));

      if (timeFilter === 'Last 30 Days') {
        return transactionDate >= thirtyDaysAgo;
      } else if (timeFilter === 'Last 60 Days') {
        return transactionDate >= sixtyDaysAgo;
      } else if (timeFilter === 'Last 90 Days') {
        return transactionDate >= ninetyDaysAgo;
      }
      return true; // 'All' seçildiğinde tüm verileri göster
    }).filter(transaction =>
      transaction.coinName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      transaction.walletAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
      formatDate(transaction.time).includes(searchTerm)
    );

    const renderTableRows = () => {
      return filteredTransactions.map((transaction, index) => (
        <tr key={index}>
          <td>{transaction.coinName}</td>
          <td>{transaction.amount}</td>
          <td>{formatDate(transaction.time)}</td>
          <td className='Recent-information'>{t('Address')}: <span>{transaction.walletAddress}</span></td>
          <td className='completed'>{t('Completed')} <span onClick={() =>{setDetails({
                                                                                                                status: 'Completed',
                                                                                                                coin:  transaction.coinName,
                                                                                                                depositAmount: transaction.amount,
                                                                                                                date: formatDate(transaction.time),
                                                                                                                depositNetwork: transaction.network,
                                                                                                                receiverAddress: transaction.walletAddress,
                                                                                                                txId:''
                                                                                                              }) ;setShowDetails(true); }}>
            <IoMdDocument /></span>
          </td>
        </tr>
      ))
    };

    useEffect(() => {
      const HistoryData = async () => {
        
        try {
          
         console.log(History);
        } catch (error) {
          console.error('Error fetching wallet data:', error);
        }
      };
      HistoryData();
  }, [History]);

    return (
      <div className="recent-activities">
        <div className="recent-header">  
          <div className="recent-title">{t('Recentdeposit')}</div>
          <div className="recent-controls">
            <select onChange={handleTimeFilterChange}>
              <option value="All">{t('All')}</option>
              <option value="Last 30 Days">{t('Last-30-Days')}</option>
              <option value="Last 60 Days">{t('Last-60-Days')}</option>
              <option value="Last 90 Days">{t('Last-90-Days')}</option>
            </select>
            <input type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
            <button className='recent-Button'>🔍</button>
          </div>
        </div>
        <div className="transaction-table-header">
        <table>
          <thead>
            <tr>
              <th>{t('Coin')}</th>
              <th>{t('Amount')}</th>
              <th>{t('Date')}</th>
              <th>{t('Information')}</th>
              <th>{t('Status')}</th>
            </tr>
          </thead>
        </table>
        </div>
        <div className='transaction-table-content'>
          <div className="transaction-table-header-Mobile">
          <table>
            <thead>
              <tr>
              <th>{t('Coin')}</th>
              <th>{t('Amount')}</th>
              <th>{t('Date')}</th>
              <th>{t('Information')}</th>
              <th>{t('Status')}</th>
              </tr>
            </thead>
          </table>
        </div>
        {filteredTransactions.length > 0 ? <table>
          <tbody>
            {renderTableRows()}
          </tbody>
        </table>:<div className="no-data">{t('No-data')} </div>}
      </div>
        {showDetails && <DepositDetails details={details} onClose={DetailsClose}/>}
      </div>
    );
  }
  
  export default RecentActivities;