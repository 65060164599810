import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import './Withdraw_.css';
import CoinIcon from '../CoinIcon/CoinIcon';
import WithdrawRecent from './WithdrawRecent';
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import { useTranslation } from 'react-i18next';
function Withdraw() {
    const {t} = useTranslation();
    const {SelectedCoin, setSelectedCoin} = useContext(Degiskenler);
    const [isInputFocused, setInputFocused] = useState(false);
    const [total, setTotal] = useState(0);
    const [inOrder, setInOrder] = useState(0);
    const [available, setAvailable] = useState(0);

    const [network, setNetwork] = useState('Endurance');
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState('');

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          color: 'white', // Seçeneklerin yazı rengi
          backgroundColor: state.isSelected ? '#292d34' : state.isFocused ? '#292d34' : null,
        }),
        control: styles => ({
          ...styles,
          width: '100%',
          backgroundColor: '#292d34',
          color: 'white'
        }),
        input: (styles) => ({
            ...styles,
            color: 'white',
        }),
        container: (base) => ({
            ...base,
            color: 'white',
            width: '100%' // Select bileşeninin genişliği
          }),
        singleValue: (provided, state) => ({
          ...provided,
          color: 'white', // Seçilen değerin yazı rengi
          backgroundColor: '#292d34',
          opacity: isInputFocused ? 0.5 : 1,
        }),
        placeholder: (provided, state) => ({
          ...provided,
          color: 'white', // Placeholder yazı rengi
          backgroundColor: '#292d34',
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#292d34', // Açılan menünün arka plan rengi
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)',
            width: '100%'
        }),
        menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#292d34',
        padding: 0,
        border: 'none'
        })
    };
    const options = [
        { value: 'BTC', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'BTC'} CoinIcon_={true} CoinName_= {true} /> {'(BTC)'}</span>) },
        { value: 'ETH', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'ETH'} CoinIcon_={true} CoinName_= {true} /> {'(ETH)'}</span>) },
        { value: 'USDT', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'USDT'} CoinIcon_={true} CoinName_= {true} /> {'(USDT)'}</span>) },
        { value: 'USDC', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'USDC'} CoinIcon_={true} CoinName_= {true} /> {'(USDC)'}</span>) },
        { value: 'BNB', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'BNB'} CoinIcon_={true} CoinName_= {true} /> {'(BNB)'}</span>) },
        { value: 'XRP', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'XRP'} CoinIcon_={true} CoinName_= {true} /> {'(XRP)'}</span>) },
        { value: 'DOGE', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'DOGE'} CoinIcon_={true} CoinName_= {true} /> {'(DOGE)'}</span>) },
        { value: 'ADA', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'ADA'} CoinIcon_={true} CoinName_= {true} /> {'(ADA)'}</span>) },
        { value: 'TRX', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'TRX'} CoinIcon_={true} CoinName_= {true} /> {'(TRX)'}</span>) },
        { value: 'AVAX', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'AVAX'} CoinIcon_={true} CoinName_= {true} /> {'(AVAX)'}</span>) },
        { value: 'SHIB', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'SHIB'} CoinIcon_={true} CoinName_= {true} /> {'(SHIB)'}</span>) },
        { value: 'DOT', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'DOT'} CoinIcon_={true} CoinName_= {true} /> {'(DOT)'}</span>) },
        { value: 'BCH', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'BCH'} CoinIcon_={true} CoinName_= {true} /> {'(BCH)'}</span>) },
        { value: 'LINK', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'LINK'} CoinIcon_={true} CoinName_= {true} /> {'(LINK)'}</span>) },
        { value: 'LTC', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'LTC'} CoinIcon_={true} CoinName_= {true} /> {'(LTC)'}</span>) },
        { value: 'NEAR', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'NEAR'} CoinIcon_={true} CoinName_= {true} /> {'(NEAR)'}</span>) },
        { value: 'MATIC', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'MATIC'} CoinIcon_={true} CoinName_= {true} /> {'(MATIC)'}</span> )},
        { value: 'UNI', label: (<span className='Withdraw-CoinIcon-label'> <CoinIcon CoinID_={'UNI'} CoinIcon_={true} CoinName_= {true} /> {'(UNI)'}</span>) },
        { value: 'Pepe', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'Pepe'} CoinIcon_={true} CoinName_= {true} /> {'(Pepe)'}</span>) },
        { value: 'ARB', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'ARB'} CoinIcon_={true} CoinName_= {true} /> {'(ARB)'}</span>) },
        { value: 'FLOKI', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'FLOKI'} CoinIcon_={true} CoinName_= {true} /> {'(FLOKI)'}</span>) },
        { value: 'XLM', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'XLM'} CoinIcon_={true} CoinName_= {true} /> {'(XLM)'}</span>) },
        { value: 'BTT', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'BTT'} CoinIcon_={true} CoinName_= {true} /> {'(BTT)'}</span>) },
        { value: 'FIL', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'FIL'} CoinIcon_={true} CoinName_= {true} /> {'(FIL)'}</span>) },
        { value: 'ATOM', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'ATOM'} CoinIcon_={true} CoinName_= {true} /> {'(ATOM)'}</span>) },
        { value: 'MKR', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'MKR'} CoinIcon_={true} CoinName_= {true} /> {'(MKR)'}</span>) },
        { value: 'VET', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'VET'} CoinIcon_={true} CoinName_= {true} /> {'(VET)'}</span>) },
        { value: 'FTM', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'FTM'} CoinIcon_={true} CoinName_= {true} /> {'(FTM)'}</span>) },
        { value: 'MANA', label: (<span className='Deposit-CoinIcon-label'> <CoinIcon CoinID_={'MANA'} CoinIcon_={true} CoinName_= {true} /> {'(MANA)'}</span>) },
    
        
    ];
    const handleChange = selectedOption => {
        setSelectedCoin(selectedOption.value);
        setInputFocused(false);
    };
    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText(); // Pano içeriğini okur
            setAddress(text); // Input alanına pano içeriğini yerleştirir
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
        }
    };
    useEffect(() => {
        
    }, [setTotal, setInOrder, setAvailable]);
    return (
        <div className='Transaction-Panel'>
         <h2>{t('Withdraw')}</h2>
         <div className='Transaction-Content'>
            <div className='Transaction-Left-Panel'>
                <div className='Transaction-Select-Coin'>
                    <div className='Transaction-Select-Coin-label'>{t('Coin')}:</div>
                    <div className="Transaction-input-group">
                        <Select
                            value={options.find(option => option.value === SelectedCoin)}
                            onChange={handleChange}
                            options={options}
                            styles={customStyles}
                            placeholder="Select or search a coin..."
                            isSearchable={true}
                            noOptionsMessage={() => "No matching results"}
                            loadingMessage={() => "Loading..."}
                            onFocus={() => setInputFocused(true)}
                            onBlur={() => setInputFocused(false)}
                            onMenuOpen={() => setInputFocused(true)}
                            onMenuClose={() => setInputFocused(false)}
                        />
                    </div>
                </div>
                <div className="Transaction-coin-balance">
                    <div className="Transaction-balance-item">
                        <label>{t('Total')}:</label>
                        <span>{total} {SelectedCoin}</span>
                    </div>
                    <div className="Transaction-balance-item">
                        <label>{t('Inorder')}:</label>
                        <span>{inOrder} {SelectedCoin}</span>
                    </div>
                    <div className="Transaction-balance-item">
                        <label>{t('Available')}:</label>
                        <span>{available} {SelectedCoin}</span>
                    </div>
                </div>
                <div className="tips-container">
                    <ul>
                        <li>{t('Use the verification')}</li>
                        <li>{t('Your withdrawals will')}</li>
                        <li>{t('Funds become available')}</li>
                    </ul>
                </div>
            </div>
            <div className='Transaction-Right-Panel'>
                <div className="network-select-container">
                    <label htmlFor="">{t('Select Network')}</label>
                    <div className='Transaction-network-select-network'>
                        <div onClick={() => setNetwork('Endurance')} className={network === 'Endurance' ? 'Transaction-network-select-item-active' : 'Transaction-network-select-item-inactive'}>Polygon</div>
                        <div onClick={() => setNetwork('DEP20')} className={network === 'DEP20' ? 'Transaction-network-select-item-active' : 'Transaction-network-select-item-inactive'}>BNB Smart Chain <br></br>(DEP20)</div>
                        <div onClick={() => setNetwork('ERC20')} className={network === 'ERC20' ? 'Transaction-network-select-item-active' : 'Transaction-network-select-item-inactive'}>Ethereum <br></br>(ERC20)</div>
                    </div>
                    
                </div>
                <div className='Transaction-Enter-Content'>
                    <div className='Transaction-Enter-input'>
                        <label onClick={() => handlePaste()}>{t('Paste')}</label>
                        
                        <input type="text" value={address} onChange={e => setAddress(e.target.value)} placeholder="Recipient's address" />
                    </div>
                    <div className="Transaction-Enter-input">
                        <label onClick={() => setAmount(available)}>{t('Select All')}</label>
                        <input type="text" value={amount} onChange={e => setAmount(e.target.value)} placeholder="Amount"/>

                    </div>
                </div>
                <div className="balance-info">
                    <div className='balance-info-items1'>
                        <p>{t('Available')}: <span>{available} {SelectedCoin}</span></p>
                        <p>{t('Minimum Withdrawal')}: 0 {SelectedCoin}</p>
                    </div>
                    <div className='balance-info-items2'>
                        <p>{t('Transaction Fee')}: 0.00 {SelectedCoin}</p>
                        <p>{t('You will get')}: {amount ? (parseFloat(amount) - 0.00).toFixed(0) : 0} {SelectedCoin}</p>
                    </div>
                       
                </div>
                <button type="submit" className="Transaction-Submit">{t('Submit')}</button>

            </div>
         </div>
         <WithdrawRecent />
        </div>
    );
}

export default Withdraw;
