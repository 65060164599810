import './Contracts.css';
import React, {} from 'react';
import Navbar from '../../components/navbar/Navbar-Contracts';

const termsconcitions = () => {

    return(
        <div className='Contracts-Content'>
            <Navbar />
            <div className='Contracts-Header'>Terms and Conditions</div>
            <div className='Contracts-Text'>
                <div className='Contracts-info-Header'>Terms Of Use</div>
                <div className='Contracts-info'>
                    These Terms of Use govern your access to and use of all content, products, and services available at the GameBIT website (the "Service") operated by GameBIT ("us", "we", or "our"). Your use of our services is conditional upon your acceptance of these terms and conditions, as well as any other operating rules and policies that we have published or may publish in the future. It is important to review this Agreement thoroughly before accessing or using our services. By utilizing any part of our services, you consent to adhere to these Terms. If you disagree with any part of these terms, you should refrain from accessing and using our services.
                </div>
                <div className='Contracts-info-Header'>Copyright</div>
                <div className='Contracts-info'>
                    At GameBIT, we reserve all copyrights on text or images on our platform. The text or images on our platform may not be reproduced or distributed without the explicit prior permission of GameBIT. Should there be any authorized use of content, the following conditions must be adhered to: The source of the copied material must be credited as GameBIT, and this attribution must appear in all forms of distribution.
                </div>
                <div className='Contracts-info-Header'>E-mail Communication</div>
                <div className='Contracts-info'>
                    You may opt to communicate with us via e-mail. However, you should be aware that the security of internet e-mail is unpredictable. By choosing to send confidential or sensitive messages that are unencrypted, you acknowledge and accept the risks associated with such uncertainties and the potential compromise of confidentiality or privacy over the internet.
                </div>
                <div className='Contracts-info-Header'>No Warranty</div>
                <div className='Contracts-info'>
                    The information provided on the GameBIT platform is offered as a service and promotion to its users, subscribers, customers, and others. It does not constitute legal or other professional advice. While we strive to provide accurate and helpful information, we cannot guarantee the results that may be obtained from its use. Furthermore, we offer no warranty of any kind, whether express or implied, including but not limited to warranties of performance or suitability for a particular purpose.
                </div>
                <div className='Contracts-info-Header'>No Liability</div>
                <div className='Contracts-info'>
                    GameBIT is not liable to any user or any other party for damages of any kind, including but not limited to direct, indirect, consequential, incidental, or other types of costs or damages, arising from the use of the information contained in our communications or services.
                </div>
                <div className='Contracts-info-Header'>Changes</div>
                <div className='Contracts-info'>
                    We reserve the right to make modifications to the information provided on the GameBIT platform at any time without prior notice. We are under no obligation to update any information that appears in our communications or on our services.
                </div>
                <div className='Contracts-info-Header'>Intellectual Property</div>
                <div className='Contracts-info'>
                    The Agreement does not transfer any of GameBIT's or third-party intellectual property rights from us to you. All rights, titles, and interests in and to such property will remain (as between the parties) solely with GameBIT and its licensors.
                </div>
                <div className='Contracts-info-Header'>Third Party Services</div>
                <div className='Contracts-info'>
                    In utilizing the Services provided by GameBIT, you may engage with third-party services, products, software, embeds, or applications developed by a third party ("Third Party Services"). You acknowledge that any use of Third Party Services is entirely at your own risk. GameBIT is not responsible or liable for any third-party websites or services. You further agree that GameBIT shall not be responsible or liable for any damage or loss claimed to have been caused by, or in connection with, the use of any such content, goods, or services available on or through any third-party websites or services.
                </div>
                <div className='Contracts-info-Header'>Accounts</div>
                <div className='Contracts-info'>
                    When any part of our Services necessitates an account, you agree to provide complete and accurate information during the registration process. You are solely responsible and liable for all activities conducted through your account. It is your responsibility to maintain the confidentiality of your account details and password, and to keep your account information current. You must ensure the security of the account used to access the Service. Sharing or misusing your access credentials is prohibited. If you become aware of any unauthorized use of your account or any other security breach, you must immediately notify us.
                </div>
                <div className='Contracts-info-Header'>Termination</div>
                <div className='Contracts-info'>
                    We reserve the right to terminate or suspend your access to all or any part of our Services at any time, for any reason, with or without prior notice, effective immediately. Should you decide to terminate the Agreement or your account, you can cease using our Services. Certain provisions of the Agreement, which by their nature are intended to survive termination, will continue in effect. This includes, but is not limited to, ownership clauses, warranty disclaimers, indemnity, and limitations of liability.
                </div>
                <div className='Contracts-info-Header'>Disclaimer</div>
                <div className='Contracts-info'>
                    Our Services are provided on an "AS IS" and "AS AVAILABLE" basis. GameBIT and its suppliers and licensors expressly disclaim all warranties, whether express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose, and non-infringement.
                    <br></br>
                    <br></br>
                    Neither GameBIT nor its suppliers and licensors guarantee that the Services will be error-free or that access to them will be continuous or uninterrupted. You acknowledge that you download from, or otherwise obtain content or services through, our Services at your own discretion and risk.
                </div>
                <div className='Contracts-info-Header'>Changes</div>
                <div className='Contracts-info'>
                    GameBIT reserves the right, at our sole discretion, to modify or replace these Terms at any time. If we make changes that are material, we will notify you by posting on our website, or by sending you an email or other form of communication before the changes take effect. We will provide a reasonable notice period after which the new terms will take effect. If you disagree with our changes, you should stop using our Services within the designated notice period, or after the changes become effective. Your continued use of our Services will be governed by the new terms.
                </div>
                <br></br>
                <br></br>
             </div>
        </div>
    )
}
export default termsconcitions