import React, {} from 'react';
import './DashboardMenu_.css';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
const DashboardMenu = ({ DashboardButtons }) => {
    const { t } = useTranslation();
    const handleButtonClick = debounce((buttonName) => {
        DashboardButtons(buttonName);
    }, 350); // 100 milisaniye delay
    return (
        <div className='DashboardMenu-Content'>
            <div className='Dashboard-Left-line'></div>
            <div className='Dashboard-Button' onClick={() => handleButtonClick('Wallet')}>
                <img src="Wallet.png" alt="" />
                <label htmlFor="">{t('Wallet')}</label>
            </div>
            <div className='Dashboard-Menu' >
                <div className='Dashboard-Menu-Items' onClick={() => handleButtonClick('BuySel')}>
                    <img src="chart.png" alt="" />
                    <label htmlFor="">{t('BuySell')}</label>
                </div>

                <div className='Dashboard-Menu-Items'  onClick={() => handleButtonClick('Deposit')}>
                    <img src="money.png" alt="" />
                    <label htmlFor="">{t('Deposit')}</label>
                </div> 
                <div className='Dashboard-Menu-Items'  onClick={() => handleButtonClick('Withdraw')}>
                    <img src="transaction.png" alt="" />
                    <label htmlFor="">{t('Withdraw')}</label>
                </div> 
                <div className='Dashboard-Menu-Items'>
                    <img src="nft.png" alt="" />
                    <label htmlFor="">{t('NFTMarket')}</label>
                    <div className='Dashboard-Comming'>
                        <img src="coming-soon.png" alt="" />
                    </div>
                </div>
                <div className='Dashboard-Menu-Items' onClick={() => handleButtonClick('Referance')}>
                    <img src="refer.png" alt="" />
                    <label htmlFor="">{t('Referance')}</label>
                </div>                  
                
            </div>
            <div className='Dashboard-Menu-Down'>
                <div className='Dashboard-Progress'>
                    <div className="Dashboard-progress-bg">
                        <div className="Dashboard-progress-bar">
                            <h3 className="raised">$12,518&nbsp;{t('collected')} </h3>
                        </div>
                    </div>
                    <h3 className="goal">0.6%</h3>
                </div>
            </div>    
        </div>
    );
}

export default DashboardMenu;