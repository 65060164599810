import React from 'react'
import { Outlet } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google';
import Footer from '../Footer/Footer'
import { GlobalSaglayici } from '../../Context/GlobalDegiskenler';
const Layout = () => {
  return (
    <>
    <GlobalSaglayici>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Outlet />
          <Footer />
      </GoogleOAuthProvider>
    </GlobalSaglayici>
    </>
  )
}

export default Layout