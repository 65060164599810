import './Login_.css';
import React, {useContext, useState, useEffect  } from 'react';
import {  Link as RouterLink } from "react-router-dom";
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import Cookies from 'js-cookie';
import { GoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
function Login() {
    const { t } = useTranslation();
    const {Register, setRegister, userCode, setuserCode, Login, setLogin, setLoginStatus, setShowLogin} = useContext(Degiskenler);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [LoginMismatch, setLoginMismatch] = useState(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [Email, setEmail] = useState('');
    const [ThereIsEmail, setThereIsEmail] = useState(false);
    const [Rpassword1, setRPassword1] = useState('');
    const [Rpassword2, setRPassword2] = useState('');
    const [RpasswordMismatch, setRpasswordMismatch] = useState(false);

    const [CheckboxPolicy, setCheckboxPolicy] = useState(false);


    const [code, setCode] = useState(new Array(6).fill(""));
    const [timer, setTimer] = useState(120); // 2 dakika = 120 saniye
    const [codeMismatch, setcodeMismatch] = useState(false);
    const [resendVisible, setResendVisible] = useState(false);

    const [ForgotEmail, setForgotEmail] = useState('');

    const [Newpassword1, setNewPassword1] = useState('');
    const [Newpassword2, setNewPassword2] = useState('');
    const [NpasswordMismatch, setNpasswordMismatch] = useState(false);

    
    const [Activation, setActivation] = useState(false);
    const [Forgot, setForgot] = useState(false);
    const [ForgotActivation, setForgotActivation] = useState(false);
    const [NewPassword, setNewPassword] = useState(false);
    const [Loader, setLoader] = useState(false);

    
   
    const InputFocus = (event, FocusID) => {
        event.preventDefault();
        if(FocusID === 0)
        {
            setThereIsEmail(false);
        }
        if(FocusID === 1)
        {
            setRpasswordMismatch(false);
            setNpasswordMismatch(false);
            setLoginMismatch(false);
        }
        if(FocusID === 2)
        {
            setcodeMismatch(false);
            event.target.select()
        }
    };
    const LoginButton = async (event) => {
        event.preventDefault();
        setLoader(true);
        const userData = {
            email: username,
            password: password.toString(),
        };
        try {
            const response = await fetch('https://game-bit.com/api/Users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                if(errorResponse.message === 'attempt'){
                    alert('Too many failed login attempts. Please try again in 30 minutes.');
                }

                setLoginMismatch(true);
                setLoader(false);
                return;
            }

            const responseData = await response.json(); 
            if(responseData.accountstatus === 0)
            {   
                setEmail(username);
                setTimer(parseInt(responseData.time));
                setLogin(false);
                setActivation(true);
            }
            if(responseData.accountstatus === 1)
            {
                Cookies.set('accessToken', responseData.token, { expires: 2/24, secure: true, sameSite: 'strict' }); // 1 saat sürecek
                Cookies.set('refreshToken', responseData.refreshToken, { expires: 7, secure: true, sameSite: 'strict' }); // 7 gün sürecek
                setLoginStatus(true);
                setShowLogin(false);
            }
            setLoader(false);
            console.log('Login successful:', responseData);
            // Başarılı bir kayıt sonrası gerekli durum güncellemeleri
           
        } 
        catch (error)
        {
            console.error('Registration failed:', error);
            setLoader(false);
        }
        // Burada giriş doğrulama işlemleri yapabilirsiniz
    };
    const RegisterPanel = async  () => {
        setFirstname("");
        setLastname("");
        setEmail("");
        setThereIsEmail("");
        setRPassword1("");
        setRPassword2("");
        setRpasswordMismatch(false);
        setLogin(false);
        setRegister(true);
        
    };
    const RegisterButton = async  (event) => {
        event.preventDefault();
        setLoader(true);
        if (Rpassword1 !== Rpassword2) {
            setRpasswordMismatch(true);
            setLoader(false);
            return;
        }
        const userData = {
            firstName: firstname,
            lastName: lastname,
            email: Email,
            password: Rpassword1,
            referenceCode: userCode
        };
        try {
            const response = await fetch('https://game-bit.com/api/Users/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Registration failed:', errorData.message);
                if(errorData.message === "Email is already registered"){
                    setThereIsEmail(true);
                }
                setLoader(false);
                return;
            }

            const responseData = await response.json();  // Yanıtı JSON olarak al
            console.log('Registration successful:', responseData.message);
            // Başarılı bir kayıt sonrası gerekli durum güncellemeleri
            setCode(new Array(6).fill(""));
            setTimer(120);
            setResendVisible(false);
            setRegister(false);
            setActivation(true);
            setLoader(false);
        } 
        catch (error)
        {
            console.error('Registration failed:', error);
            setLoader(false);
        }
       
    };
    const ActivationCodeFunc = (element, index) => {
        if (isNaN(element.value)) return false;
        setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);
    
        if (element.nextSibling) {
          element.nextSibling.focus();
        }
    };
    const NewActivationCode = async () => {

        setLoader(true);
        const userData = {
            Email: Email
        };
        try {
            const response = await fetch('https://game-bit.com/api/Users/activation/resend-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                if(errorData.time)
                {
                    setTimer(parseInt(errorData.time));
                    setResendVisible(false);
                }
                setLoader(false);
                return;
            }
            setCode(new Array(6).fill(""));
            setTimer(120);
            setResendVisible(false);
            setLoader(false);
        } 
        catch (error)
        {
            console.error('Registration failed:', error);
            setLoader(false);
        }
   
        
    };
    const ActivationButton = async  () => {
        setLoader(true);
        const _activationCode = code.join('');
        const userData = {
            Email: Email,
            ActivationCode: _activationCode
        };
        try {
            const response = await fetch('https://game-bit.com/api/Users/activation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                if(errorData.message === "Activation code has expired or does not exist."){
                   console.log("Activation code has expired or does not exist.");
                }
                if(errorData.message === "Activation code has expired or does not exist."){
                    console.log("Activation code has expired or does not exist.");
                } 
                if(errorData.message === "Failed to activate user."){
                    console.log("Failed to activate user.");
                }
                setcodeMismatch(true);
                setLoader(false);
                return;
            }

            const responseData = await response.json();
            Cookies.set('accessToken', responseData.token, { expires: 2/24, secure: true, sameSite: 'strict' }); // 1 saat sürecek
            Cookies.set('refreshToken', responseData.refreshToken, { expires: 7, secure: true, sameSite: 'strict' }); // 7 gün sürecek
            setActivation(false); 
            setLogin(true);
            setLoader(false);
            setLoginStatus(true);
            setShowLogin(false);
        } 
        catch (error)
        {
            console.error('Registration failed:', error);
            setLoader(false);
        }

    };
    const ForgotPanel = async  () => {
        setForgotEmail("");
        setCode(new Array(6).fill(""));
        setNewPassword1("");
        setNewPassword2("");
        setNpasswordMismatch(false);
        setLogin(false);
        setForgot(true);
        
    };
    const ForgotEposta = async  (event) => {
        event.preventDefault();
        setLoader(true);
        const userData = {
            Email: ForgotEmail
        };
        try {
            const response = await fetch('https://game-bit.com/api/Users/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                if(errorData.time)
                {
                    setTimer(parseInt(errorData.time));
                    setForgot(false);
                    setForgotActivation(true);
                    setLoader(false);
                }
                return;
            }

           
            setTimer(120);
            setForgot(false);
            setForgotActivation(true);
            setLoader(false);
        } 
        catch (error)
        {
            console.error('Registration failed:', error);
            setLoader(false);
        }

    };
    const ForgotCodeFunc = (element, index) => {
        if (isNaN(element.value)) return false;
        setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    }; 
    const NewForgotCode = async () => {
        setLoader(true);
        const userData = {
            Email: ForgotEmail
        };
        try {
            const response = await fetch('https://game-bit.com/api/Users/forgot-password/resend-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                setTimer(parseInt(errorData.time));
                setResendVisible(false);
                setLoader(false);
                return;
            }
            setCode(new Array(6).fill(""));
            setTimer(120);
            setResendVisible(false);
            setLoader(false);
        } 
        catch (error)
        {
            console.error('Registration failed:', error);
            setLoader(false);
        }
   
        
    };
    const ForgotCodeButton = async  () => {
        setLoader(true);
        const _activationCode = code.join('');
        const userData = {
            Email: ForgotEmail,
            ForgotCode: _activationCode
        };
        try {
            const response = await fetch('https://game-bit.com/api/Users/verify-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                if(errorData.message === "Forgot code has expired or does not exist."){
                    console.log("Forgot code has expired or does not exist.2");
                } 
                if(errorData.message === "Incorrect activation code."){
                    console.log("Incorrect activation code.");
                }
                setcodeMismatch(true);
                setLoader(false);
                return;
            }

            const responseData = await response.json();

            Cookies.set('securityCode', responseData.securityCode, { expires: 10/1440, secure: true, sameSite: 'strict' }); // 10 dakika sürecek
            const deger2 = Cookies.get('securityCode');
            console.log('Security Code:', deger2);
            setForgotActivation(false); 
            setNewPassword(true);
            setLoader(false);
        } 
        catch (error)
        {
            console.error('Forgot Code failed:', error);
            setLoader(false);
        }

    };
    const NewPasswordButton = async  (event) => {
        event.preventDefault();
        setLoader(true);
        if (Newpassword1 !== Newpassword2) {
            setNpasswordMismatch(true);
            setLoader(false);
            return;
        }
        const userData = {
            email: ForgotEmail,
            password: Newpassword1,
            securityCode: Cookies.get('securityCode')
        };
        try {
            const response = await fetch('https://game-bit.com/api/Users/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                console.error('unsuccessful');
            }
            setNewPassword(false); 
            setLogin(true);
            setLoader(false);
        } 
        catch (error)
        {
            console.error('Registration failed:', error);
            setLoader(false);
        }
    };
    const GoogleLoginSuccess = async  (credential) => {
        setLoader(true); 
        const userData = {
            token: credential
        };
        try {
            const response = await fetch('https://game-bit.com/api/Users/login-google', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });

            if (!response.ok) {
                setLoader(false);
                return;
            }

            const responseData = await response.json(); 
            Cookies.set('accessToken', responseData.token, { expires: 2/24, secure: true, sameSite: 'strict' }); // 1 saat sürecek
            Cookies.set('refreshToken', responseData.refreshToken, { expires: 7, secure: true, sameSite: 'strict' }); // 7 gün sürecek
            setLoginStatus(true);    
            setShowLogin(false);
            setLoader(false);   
        } 
        catch (error)
        {
            console.error('Registration failed:', error);
            setLoader(false);
        }
    };
    const LeftPanel = async  () => {
        setFirstname("");
        setLastname("");
        setEmail("");
        setThereIsEmail("");
        setRPassword1("");
        setRPassword2("");
        setRpasswordMismatch(false);
        setLogin(!Login);
        setRegister(!Register);
    };



    useEffect(() => {
        if (timer > 0) {
          const intervalId = setInterval(() => {
            setTimer(timer - 1);
          }, 1000);
    
          return () => clearInterval(intervalId);
        } else {
          setResendVisible(true);
        }
    }, [timer]);

    return (
      <div className='Login-Background'>
        <div className='Login-Center'>
            <div className='LoginLeft'>
                <div className="screen__background">
                    <span className="screen__background__shape screen__background__shape4"></span>
                    <span className="screen__background__shape screen__background__shape3"></span>		
                    <span className="screen__background__shape screen__background__shape2"></span>
                    <span className="screen__background__shape screen__background__shape1"></span>
		        </div>	
                <div className='Login-start'>
                    <label htmlFor="">{t('Discover More Top')}<br></br>{t('Discover More Down')}</label>
                </div>	
                <label className='Login-label' htmlFor="">{t('Login-Info')}</label>
                <div className='Login-Left-Button' onClick={() => LeftPanel()}>{Login ? t('Sign up') :  t('Sign in')}</div>
                
                <div className='Login-shield'>
                    <img src="shield.png" alt="" />
                </div>

            </div>
            <div className='LoginRight'>
                <button className='Login-Close' onClick={() => setShowLogin(false)}>
                    <img src="Close.png" alt="Close" />
                </button>
                <div className={Login ? 'Login-container active' : 'Login-container'}>
                    <h2>{t('Sign in')} </h2>
                    <p>{t('NoAccont')} <label className='Login-Link' onClick={()=>{RegisterPanel();}}>{t('CreateAccount')} </label></p>
                    <form onSubmit={LoginButton}>
                        <div className='InputLogin'>
                            <div htmlFor="username">{t('Email')}</div>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                placeholder="you@example.com"
                            />
                        </div>
                        
                        <div className={LoginMismatch ? 'InputLoginRed' : 'InputLogin'}>
                            <div htmlFor="password">{t('Password')}</div>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                pattern=".{8,}"
                                placeholder="Enter & character or more"
                                onFocus={(e) => InputFocus(e, 1)}
                            />
                            <label>{t('Password2')}</label>
                        </div>
                    
                        <div className="Login-checkbox">
                            <input
                                type="checkbox"
                                id="remember"
                                name="remember"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            <label htmlFor="remember">{t('Remember me')}</label>
                        </div>
                
                        <div className="forgot-password" onClick={()=>{ForgotPanel();}} >{t('Forgot password')}</div>

                        <button className='Login-Button' type="submit">{t('Login')}</button>
                        <div className='Login-Text'>{t('Login With')}</div>
                        
                    </form>
                    <div className='Login-Google'>
                        <GoogleLogin 
                            width={350}
                            onSuccess={credentialResponse => {GoogleLoginSuccess(credentialResponse.credential);}}
                            onError={() => {console.log('Login Failed');}}
                        />
                    </div>
                </div>
                <div className={Register ? 'Register-container active' : 'Register-container'}>
                    <h2>{t('Sign up')}</h2>
                    <p>{t('Already-have-an-account')} <label  className='Register-Link' onClick={()=>{setRegister(false); setLogin(true);}}> {t('Sign-in-instead')} </label></p>
                    <form onSubmit={RegisterButton}>
                        <div className='Input'>
                            <input
                                type="text"
                                name="firstname"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                required
                                placeholder={t('First Name')}
                            />
                        </div>
                        <div className='Input'>
                            <input
                                type="text"
                                name="lastname"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                required
                                placeholder={t('Last Name')}
                            />
                        </div>
                        <div className={ThereIsEmail ? 'InputRed' : 'Input'}>
                            <input
                                type="text"
                                name="email"
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                placeholder={t('Your Email')}
                                onFocus={(e) => InputFocus(e, 0)}
                            />
                            <label>{t('Email-is-already-registered')}</label>
                        </div>
                        <div className='Input'>
                            <input
                                type="password"
                                name="Rpassword1"
                                value={Rpassword1}
                                onChange={(e) => setRPassword1(e.target.value)}
                                required
                                pattern=".{8,}"
                                placeholder={t('Create a password (min. 8 characters)')}
                            />
                        </div>
                        <div className={RpasswordMismatch ? 'InputRed' : 'Input'}>
                            <input
                                type="password"
                                name="Rpassword2"
                                value={Rpassword2}
                                onChange={(e) => setRPassword2(e.target.value)}
                                onFocus={(e) => InputFocus(e, 1)}
                                required
                                pattern=".{8,}"
                                placeholder={t('Repeat Password')}
                            />
                            <label>{t('Password-is-not-the-same')}</label>
                        </div>
                        <div className='Input'>
                            {userCode !== "" && <p className='ReferenceCode'>{t('Reference-Code')}</p>}
                            <input
                                type="text"
                                name="code"
                                value={userCode}
                                onChange={(e) => setuserCode(e.target.value)}
                                placeholder={t('Reference Code')}
                            />
                        </div>
                        <div className="Register-checkbox">
                            <input
                                type="checkbox"
                                name="Register-remember"
                                checked={CheckboxPolicy}
                                onChange={(e) => setCheckboxPolicy(e.target.checked)}
                                required
                            />
                            <span>
                                {t('I agree to the')}
                                <RouterLink to="/terms-concitions" target="_blank">
                                    <label htmlFor="" className='Terms-and-Conditions'>{t('Terms and Conditions')}</label>
                                </RouterLink>
                                    &nbsp;{t('and')}
                                <RouterLink to="/privacy-policy" target="_blank">
                                    <label htmlFor="" className='Privacy-Policy'>{t('Privacy Policy')}</label>                              
                                </RouterLink>.
                            </span>
                            
                        </div>
                
                        <button className='Login-Button' type="submit">{t('Create Account')}</button>
                    </form>
                </div>
                <div className={Activation ? 'Activation-container active' : 'Activation-container'}>
                    <img src="tick.png" alt="Email Icon" className="email-icon" />
                    <h1>{t('JUST-ONE-MORE-STEP')}<br />{t('LETS-VERIFY-YOUR-EMAIL')}</h1>
                    <p>{t('We-sent-a-code-to')} <strong>youremail@inbox.com</strong>, {t('please-check-your-inbox')}</p>
                    <div className={codeMismatch ? 'code-inputs-red' : 'code-inputs'}>
                        {code.map((data, index) => {
                        return (
                            <input
                            key={index}
                            type="text"
                            maxLength="1"
                            value={data}
                            onChange={e => ActivationCodeFunc(e.target, index)}
                            onFocus={e => InputFocus(e, 2)}
                            />
                        );
                        })}
                    </div>
                    <div className="timer-container">
                        {!resendVisible ? (
                        <span className="timer">{`0${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`}</span>
                        ) : (
                        <button className="resend-button" onClick={()=>{NewActivationCode(); }} >{t('Resend Code')}</button>
                        )}
                    </div>
                    <button className="continue-button" disabled={timer === 0} onClick={()=>{ActivationButton(); }}>{t('CONTINUE')} →</button>
                    <p className="footer-text">{t('Dont-worry')}</p>
                </div>
                <div className={Forgot ? 'Forgot-container active' : 'Forgot-container'}>
                    <button className='Forgot-Back' onClick={()=>{setForgot(false); setLogin(true);}}>
                        <img src="arrow.png" alt="Back" />
                    </button>
                    <h1>{t('Forgot Password')}</h1>
                    <h2>{t('Enter-the-email-address')}</h2>
                    <form onSubmit={ForgotEposta}>
                        <label htmlFor="email">{t('Enter Email Address')}</label>
                        <input
                            type="email"
                            name="email"
                            value={ForgotEmail}
                            onChange={(e) => setForgotEmail(e.target.value)}
                            required
                            placeholder="you@example.com"
                        />
                        <button type="submit" className="Forgot-continue-button">{t('Send')} →</button>
                    </form>
                    <div className="signup-link">{t('NoAccont')} <label className='Login-Link' onClick={()=>{setForgot(false); setRegister(true);}}>{t('Sign up')}</label></div>
                </div>
                <div className={ForgotActivation ? 'ForgotActivation-container active' : 'ForgotActivation-container'}>
                    <img src="tick.png" alt="Email Icon" className="email-icon" />
                    <h1>{t('JUST-ONE-MORE-STEP')}<br />{t('LETS-VERIFY-YOUR-EMAIL')}</h1>
                    <p>{t('We-sent-a-code-to')} <strong>youremail@inbox.com</strong>, {t('please-check-your-inbox')}</p>
                    <div className={codeMismatch ? 'code-inputs-red' : 'code-inputs'}>
                        {code.map((data, index) => {
                        return (
                            <input
                            key={index}
                            type="text"
                            maxLength="1"
                            value={data}
                            onChange={e => ForgotCodeFunc(e.target, index)}
                            onFocus={e => InputFocus(e, 2)}
                            />
                        );
                    })}
                    </div>
                    <div className="timer-container">
                        {!resendVisible ? (
                        <span className="timer">{`0${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`}</span>
                        ) : (
                        <button className="resend-button" onClick={NewForgotCode}>{t('Resend Code')}</button>
                        )}
                    </div>
                    <button className="continue-button" disabled={timer === 0} onClick={()=>{ForgotCodeButton();}}>{t('Verify')} →</button>
                    <p className="footer-text">{t('Dont-worry')}</p>
                </div>
                <div className={NewPassword ? 'NewPassword-container active' : 'NewPassword-container'}>
                    <h1>{t('New Password')}</h1>
                    <h2>{t('Your code')}</h2>
                    <form onSubmit={NewPasswordButton}>
                        <label htmlFor="email">{t('Enter-New-Password')}</label>
                        <input
                            type="password"
                            name="password"
                            value={Newpassword1}
                            onChange={(e) => setNewPassword1(e.target.value)}
                            onFocus={(e) => InputFocus(e, 1)}
                            required
                            pattern=".{8,}"
                            placeholder={t('symbols at least')}
                        />
                        <label htmlFor="email">{t('Confirm Password')}</label>
                        <div className={NpasswordMismatch ? 'NewPassordInputRed' : 'NewPassordInput'}>
                            <input
                                type="password"
                                name="password"
                                value={Newpassword2}
                                onChange={(e) => setNewPassword2(e.target.value)}
                                onFocus={(e) => InputFocus(e, 1)}
                                required
                                pattern=".{8,}"
                                placeholder="********"
                            />
                            <label>{t('Password-is-not')}</label>
                        </div>
                         
                        <button type="submit" className="Forgot-continue-button">{t('Submit')} →</button>
                    </form>                   
                </div>
                <div className={Loader ? 'loaderPanel active' : 'loaderPanel'}>
                    <div className='loader'></div>
                </div>
                
            </div>
        </div>
      </div>
    );
  }
  
export default Login;

/*
<RouterLink to="/terms-concitions" onClick={()=> {setRegister(false);setShowLogin(false);}}>
                                <label htmlFor="Register-remember" >{t('Policy')}</label>
                            </RouterLink>
*/