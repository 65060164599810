import './Contracts.css';
import React, {} from 'react';
import Navbar from '../../components/navbar/Navbar-Contracts';


const privacypolicy = () => {
    return(
        <div className='Contracts-Content'>
             <Navbar />
             <div className='Contracts-Header'>Privacy and Policy</div>
             <div className='Contracts-Text'>
                <br></br>
                <br></br>
                <div className='Contracts-info'>
                    This Privacy Policy, in conjunction with our Terms of Use, outlines the rationale behind the collection of personal information from visitors to our website(s), the usage of this information, the circumstances under which we may share it with others, and the precautions we take to ensure its security.
                <br></br>
                <br></br>
                    By accessing our website, you are affirming your acceptance of and consent to the practices outlined in this Policy. This consent extends to the collection and processing of any personal information you provide as specified herein. We reserve the right to modify this Policy periodically. It is advisable to review the Policy regularly to confirm that you are in agreement with any modifications. Continual use of our website(s) after changes have been made constitutes your acknowledgment and acceptance of these changes.
                </div>
                <div className='Contracts-info-Header'>Provided Information</div>
                <div className='Contracts-info'>
                    You may share personal information with us through various means, such as filling out forms on our website(s) or communicating with us via email or live chat.
                <br></br>
                <br></br>
                    The types of personal information you might provide include your name, email address, physical address, phone number, IP address, language preferences, and details about the pages you visit on our website. By providing this information, you explicitly agree to our processing of your personal information in line with this Policy.
                </div>

                <div className='Contracts-info-Header'>Information Collected Automatically</div>
                <div className='Contracts-info'>
                    When you visit our website(s), we automatically gather information which may include your IP address, details of your visits such as browsing activity, and your interactions with our website. This information helps us understand how you use our services and may be integrated with other data you provide to enhance your experience on our site.
                </div>
                <div className='Contracts-info-Header'>Information from External Sources</div>
                <div className='Contracts-info'>
                    We may receive additional information about you from external sources. This includes data from other websites that we or our partners operate, as well as from third parties such as contractors, project partners, service providers, and analytics providers. This externally sourced information may be combined with the details you provide directly to us to enhance and personalize your experience with our services.
                </div>
                <div className='Contracts-info-Header'>Usage of Your Information</div>
                <div className='Contracts-info'>
                    We utilize your personal information for several key purposes, all aimed at enhancing your experience and providing you with relevant content:
                    <ul>
                    <li><strong>Personalized Communications</strong>: We send tailored communications based on your activities and preferences, which may include details about campaigns, activities, and events that might interest you.</li>
                    <br></br>
                    <li><strong>Service Improvement and Analytics</strong>: We analyze how you and others use our website to enhance the effectiveness of our services. This involves creating aggregate reports on trends, tracking how visitors navigate our site, gauging the response to marketing campaigns, and identifying the most effective marketing channels.</li>
                    <br></br>
                    <li><strong>Recommendations</strong>: Based on your activity on our website and potentially those of our partners, we may make personalized suggestions and recommendations about services that could be of interest to you.</li>
                    <br></br>
                    <li><strong>Feedback and Updates</strong>: We seek your feedback on our services and will notify you about any significant changes to our policies or terms of use.</li>
                    </ul>
                    Data Retention We regularly review and adjust our data retention periods. We retain your personal information on our systems only for as long as necessary to fulfill the activities for which it was collected.
                </div>
                <div className='Contracts-info-Header'>Access to Your Information</div>
                <div className='Contracts-info'>
                    We uphold strict policies regarding the sharing of your information:
                    <ul>
                        <li><strong>No Selling or Renting</strong>: We do not sell or rent your information to third parties.</li>
                        <br></br>
                        <li><strong>Controlled Sharing</strong>: We do not share your information with third parties for marketing purposes. However, we may share your data with third-party service providers, agents, subcontractors, and associated organizations that help us complete tasks and provide services to you on our behalf, such as processing and storing information or sending emails.</li>
                    </ul>
                    Third-Party Service Providers
                    <ul>
                        <li><strong>Selective Disclosure</strong>: We only disclose the personal information necessary for these third parties to deliver their services.</li>
                        <br></br>
                        <li><strong>Security Requirements</strong>: We have contracts in place with our third-party service providers that require them to keep your information secure and prohibit them from using it for their direct marketing purposes.</li>
                    </ul>
                    Privacy Protection
                    <ul>
                        <li>We are committed to taking the necessary measures to ensure that your privacy rights are maintained and protected at all times.</li>
                    </ul>
                </div>
                <div className='Contracts-info-Header'>Your Choices and Rights</div>
                <div className='Contracts-info'>
                    You have the following rights regarding the processing of your personal information:
                    <ul>
                        <li><strong>Opting Out of Marketing</strong>: You have the right to request that we do not process your personal information for marketing purposes. You can exercise this right by checking or unchecking the appropriate boxes on the forms we use to collect your data. Alternatively, you can contact us at any time via email at <strong>support@game-bit.com</strong> to update your preferences.</li>
                        <br></br>
                        <li><strong>Consent for Marketing</strong>: We will not contact you for marketing purposes unless you have given us your explicit consent to do so.</li>
                        <br></br>
                        <li><strong>Changing Marketing Preferences</strong>: You can modify your marketing preferences at any time by reaching out to us at <strong>support@game-bit.com</strong>.</li>
                    </ul>
                </div>
                <div className='Contracts-info-Header'>Updating Your Information</div>
                <div className='Contracts-info'>
                    Ensuring the accuracy of your information is a priority for us. If any of your details, such as your email address, change or if the information we hold about you is incorrect or outdated, please notify us by emailing <strong>support@game-bit.com</strong>.
                    <br></br>
                    <br></br>
                    Additionally, you have the right to request a copy of the personal information GameBIT holds about you.
                </div>
                <div className='Contracts-info-Header'>Security Measures to Protect Your Information</div>
                <div className='Contracts-info'>
                    We take significant steps to ensure that any personal information you provide is handled securely.                    <br></br>
                    <br></br>
                    <br></br>
                    However, please note that non-sensitive information, such as your email address, may be transmitted over the internet without encryption and cannot be guaranteed to be 100% secure. While we strive to protect your personal data, we cannot fully guarantee the security of any information transmitted to us, and you do so at your own risk. Once we receive your information, we make every effort to secure it within our systems.
                </div>
                <div className='Contracts-info-Header'>Profiling</div>
                <div className='Contracts-info'>
                    We may analyze your personal information to build a profile of your preferences and interests. This allows us to provide you with relevant information tailored to your needs. Additionally, we may incorporate information from external sources to enhance the accuracy and effectiveness of this profiling process.
                </div>
                <div className='Contracts-info-Header'>Use of Cookies</div>
                <div className='Contracts-info'>
                    Our website(s) use cookies to distinguish you from other visitors. Cookies are small text files that may be stored on your computer or other internet-enabled devices, such as smartphones or tablets. These files are sent by a server and stored on your hard drive, allowing the website to recognize you during subsequent visits.
                    <br></br>
                    <br></br>
                    Cookies serve various purposes in enhancing the user experience, such as making interactions more efficient. Our website(s) use different types of cookies, including some placed by third-party services that appear on our pages. We utilize cookies to:
                    <ul>
                        <li>Personalize content</li>
                        <li>Provide social media features, and</li>
                        <li>Analyze traffic to improve the services we offer.</li>
                    </ul>
                    By continuing to browse our site(s), you consent to our use of cookies.
                    <br></br>
                    <br></br>
                    Additionally, we share information about your use of our website with social media and analytics partners, who may combine it with other information you’ve provided or that they’ve collected from your use of their services.
                    <br></br>
                    <br></br>
                    You can modify or withdraw your consent regarding cookies at any time through the cookie declaration on our website. Your consent applies to the following domains: game-bit.com
                </div>
                <div className='Contracts-info-Header'>Types of Cookies We Use</div>
                <div className='Contracts-info'>
                    <ul>
                        <li><strong>Strictly Necessary Cookies</strong>: These cookies are essential for the functioning of our website and cannot be disabled in our systems. They are usually set in response to actions you take, such as adjusting your privacy settings, logging in, or filling out forms. These cookies enable core functionality like page navigation and access to secure areas. The website cannot function properly without them.</li>
                            <br></br>
                        <li><strong>Targeting Cookies</strong>: Targeting cookies may be set by our advertising partners. These cookies track visitors across websites to build a profile of your interests and display relevant advertisements on other websites. They do not directly store personal information but are based on uniquely identifying your browser and device. If you do not allow these cookies, you will experience less targeted advertising.</li>
                            <br></br>
                        <li><strong>Performance Cookies</strong>: Also known as statistical cookies, performance cookies help us count visits and track traffic sources to measure and improve our website's performance. They help us understand which pages are most and least popular and how visitors navigate the site. All data collected by these cookies is aggregated and anonymous. If you do not allow these cookies, we will not be able to monitor the performance of our site.</li>
                    </ul>
                </div>
                <div className='Contracts-info-Header'>Links to Other Websites</div>
                <div className='Contracts-info'>
                    Our website may contain links to external sites operated by other organizations. This Policy applies only to our website, so we recommend reviewing the privacy policies of other websites you visit. We are not responsible for the privacy practices of third-party sites, even if accessed through our website.
                    <br></br>
                    <br></br>
                    If you arrived at our website from a third-party link, we encourage you to check the privacy policies of the site you came from, as we cannot be responsible for their practices.
                </div>
                <div className='Contracts-info-Header'>Protection of Minors (16 or Under)</div>
                <div className='Contracts-info'>
                    We prioritize the privacy of children aged 16 or under. If you are 16 or under, please seek permission from a parent or guardian before providing us with any personal information.
                </div>
                <div className='Contracts-info-Header'>Where We Store Your Personal Information</div>
                <div className='Contracts-info'>
                    The data we collect may be transferred to and stored in countries outside the European Economic Area (EEA). It may also be processed by staff located outside the EEA who work for us. The legal protections for your data may differ in these countries compared to the EEA. By submitting your personal information, you consent to this transfer, storage, and processing. We will take reasonable steps to ensure your data is treated securely and in accordance with this Privacy Policy.
                    <br></br>
                    <br></br>
                    While we strive to protect your data, transmitting information via the internet is not entirely secure. We cannot guarantee the full security of your information during transmission, and any transfer is done at your own risk. Once your information is received, we use secure procedures and features to prevent unauthorized access.
                </div>
                <div className='Contracts-info-Header'>Data Protection Officer</div>
                <div className='Contracts-info'>
                    GameBIT has appointed a Data Protection Officer (DPO) who can be contacted for any questions regarding this Policy and our privacy practices.
                </div>
                <br></br>
                <br></br>
             </div>
        </div>
    )
}
export default privacypolicy