import React, { } from 'react';
import './WithdrawDetails_.css'; // CSS dosyası bağlantısı

function DepositDetails({ details, onClose }) {

    return (
        <div className='Withdraw-details-Panel'>
            <div className="Withdraw-details">
                <div className="Withdraw-header">
                    <h2>Withdraw Details</h2>
                    <button onClick={() => onClose()} className="close-button">✖</button>
                </div>
                <div className="Withdraw-detail-item">
                    <strong>Status:</strong> <span>{details.status}</span>
                </div>
                <div className="Withdraw-detail-item">
                    <strong>Date:</strong> <span>{details.date}</span>
                </div>
                <div className="Withdraw-detail-item">
                    <strong>Coin:</strong> <span>{details.coin}</span>
                </div>
                <div className="Withdraw-detail-item">
                    <strong>Deposit amount:</strong> <span>{details.WithdrawAmount}</span>
                </div>
                <div className="Withdraw-detail-item">
                    <strong>Free:</strong> <span>{details.Fee}</span>
                </div>
                <div className="Withdraw-detail-item">
                    <strong>Deposit Network:</strong> <span>{details.WithdrawNetwork}</span>
                </div>
                <div className="Withdraw-detail-item">
                    <strong>Deposit receiving address:</strong> <span>{details.receiverAddress}</span>
                </div>
                <div className="Withdraw-detail-item">
                    <strong>TxID/TxHash:</strong> <span>{details.txId}</span>
                </div>
            </div>
        </div>
    );
}

export default DepositDetails;
