import './WithdrawRecent_.css';
import React, { useState } from 'react';
import { IoMdDocument } from "react-icons/io";
import WithdrawDetails from './WithdrawDetails';
import { useTranslation } from 'react-i18next';
function WithdrawRecent() {
  const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [timeFilter, setTimeFilter] = useState('All');
    

    const [transactions, setTransactions] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const WithdrawClose = () => {
     
      setShowDetails(false);
    };
    const [details, setDetails] = useState({
      status: '',
      coin: '',
      WithdrawAmount: '',
      Fee: '',
      date: '', 
      WithdrawNetwork: '',
      receiverAddress: '',
      txId: ''
    });
    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
      setTransactions([]);
    };
    const handleTimeFilterChange = (event) => {
      setTimeFilter(event.target.value);
    };
    

    const filteredTransactions = transactions.filter(transaction => {
      const transactionDate = new Date(transaction.date);
      const now = new Date();
      const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));
      const sixtyDaysAgo = new Date(now.setDate(now.getDate() - 60));
      const ninetyDaysAgo = new Date(now.setDate(now.getDate() - 90));

      if (timeFilter === 'Last 30 Days') {
        return transactionDate >= thirtyDaysAgo;
      } else if (timeFilter === 'Last 60 Days') {
        return transactionDate >= sixtyDaysAgo;
      } else if (timeFilter === 'Last 90 Days') {
        return transactionDate >= ninetyDaysAgo;
      }
      return true; // 'All' seçildiğinde tüm verileri göster
    }).filter(transaction =>
      transaction.coin.toLowerCase().includes(searchTerm.toLowerCase()) ||
      transaction.information.toLowerCase().includes(searchTerm.toLowerCase()) ||
      transaction.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      transaction.date.includes(searchTerm)
    );

    const renderTableRows = () => {
      return filteredTransactions.map((transaction, index) => (
        <tr key={index}>
          <td>{transaction.coin}</td>
          <td>{transaction.amount}</td>
          <td>{transaction.fee}</td>
          <td>{transaction.date}</td>
          <td className='Withdraw-information'>Address: <span>{transaction.receiverAddress}</span></td>
          <td className={transaction.status.toLowerCase()}>{transaction.status} <span onClick={() =>{setDetails({
                                                                                                                status: transaction.status,
                                                                                                                coin:  transaction.coin,
                                                                                                                WithdrawAmount: transaction.amount,
                                                                                                                Fee: transaction.fee,
                                                                                                                date: transaction.date,
                                                                                                                WithdrawNetwork: transaction.Network,
                                                                                                                receiverAddress: transaction.receiverAddress,
                                                                                                                txId: transaction.TxID
                                                                                                              }) ;setShowDetails(true); }}>
            <IoMdDocument /></span>
          </td>
        </tr>
      ))
    };
    return (
      <div className="Withdraw-activities">
        <div className="Withdraw-header">  
          <div className="Withdraw-title">{t('Recent Withdraw')}</div>
          <div className="Withdraw-controls">
            <select onChange={handleTimeFilterChange}>
              <option value="All">{t('All')}</option>
              <option value="Last 30 Days">{t('Last 30 Days')}</option>
              <option value="Last 60 Days">{t('Last 60 Days')}</option>
              <option value="Last 90 Days">{t('Last 90 Days')}</option>
            </select>
            <input type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
            <button className='Withdraw-Button'>🔍</button>
          </div>
        </div>
        <div className="Withdraw-table-header">
        <table>
          <thead>
            <tr>
              <th>{t('Coin')}</th>
              <th>{t('Amount')}</th>
              <th>{t('Fee')}</th>
              <th>{t('Date')}</th>
              <th>{t('Information')}</th>
              <th>{t('Status')}</th>
            </tr>
          </thead>
        </table>
        </div>
        <div className='Withdraw-table-content'>
          <div className="Withdraw-table-header-Mobile">
          <table>
            <thead>
              <tr>
              <th>{t('Coin')}</th>
              <th>{t('Amount')}</th>
              <th>{t('Fee')}</th>
              <th>{t('Date')}</th>
              <th>{t('Information')}</th>
              <th>{t('Status')}</th>
              </tr>
            </thead>
          </table>
        </div>
        {filteredTransactions.length > 0 ?
        <table>
          <tbody>
            {renderTableRows()}
          </tbody>
        </table>:<div className="no-data">{t('No-data')} </div>
        }
        </div>
        {showDetails && <WithdrawDetails details={details} onClose={WithdrawClose}/>}
      </div>
    );
  }
  
  export default WithdrawRecent;