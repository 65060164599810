import React, { useState,useEffect ,useContext} from 'react';
import './Referance_.css';
import Cookies from 'js-cookie';
import { FaCopy, FaUsers   } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { PiWarningCircleFill } from "react-icons/pi";
import ReferralsList from './ReferralsList';
import { useLocation } from 'react-router-dom';
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import { useTranslation } from 'react-i18next';
function Referance() {
    const {t} = useTranslation();
    const location = useLocation();
    const {refreshToken, BackHome} = useContext(Degiskenler);
    const [timeFilter, setTimeFilter] = useState('All Accounts');
    const [SelectButton, setSelectButton] = useState('Today');
    const [SelectReferralsButton, setSelectReferralsButton] = useState('All Accounts');

    const [ReferanceCode, setReferanceCode] = useState("");
    const [receive, setreceive] = useState(5);
    const [FriendsReceive, setFriendsReceive] = useState(0);
    const [RefenceUsers, setRefenceUsers] = useState([]);
    const [Referance, setReferance] = useState([]);
    
    const handleTimeFilterChange = (event) => {
        setTimeFilter(event.target.value);
    };
    
    useEffect(() => {
        const ReferanceData = async () => {
            let Status= await refreshToken();
            if(Status){
                const token = Cookies.get('accessToken');
                try {
                    const response = await fetch('https://game-bit.com/api/Users/Referance', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });
                
                    if (!response.ok) {
                        BackHome();
                        console.log(`HTTP error! status: ${response.status}`);
                        return;
                    }
                
                    const data = await response.json();
                    setReferanceCode(data.referanceCode);
                    setreceive(data.receive);
                    setFriendsReceive(data.friendsReceive);
                    setRefenceUsers(data.refenceUsers);
                    setReferance(data.referance);
                    console.log('Referance Data:', data);
                    console.log('ReferanceCode Data:', ReferanceCode);
                } catch (error) {
                    BackHome();
                    console.log('Error Referance Data:', error);
                }
            }
        };
        ReferanceData();
    }, [location,BackHome, refreshToken,ReferanceCode, setReferanceCode,setreceive,setFriendsReceive,setRefenceUsers,setReferance]);
    
    const countTodaysDates = () => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        let count = 0;
        if(timeFilter === "All Accounts" || timeFilter === "Spot"){
            Object.values(RefenceUsers).forEach(dateString => {
                const userDate = new Date(dateString);
                userDate.setHours(0, 0, 0, 0);  // Saati sıfırla
    
                if(SelectButton ==="Today"){
                    if (userDate.getTime() === now.getTime()) {
                        count++;
                    }
                }
                if(SelectButton ==="Yesterday"){
                    now.setDate(now.getDate() - 1);
                    if (userDate.getTime() === now.getTime()) {
                        count++;
                    }
                }
                if(SelectButton ==="This Week"){
                    now.setDate(now.getDate() - 7);
                    if (userDate.getTime() >= now.getTime()) {
                        count++;
                    }
                }
                if(SelectButton ==="This Month"){
                    now.setMonth(now.getMonth() - 1);
                    if (userDate.getTime() >= now.getTime()) {
                        count++;
                    }
                }
    
                /*
                if (refDate === null || userDate.getTime() >= refDate.getTime()) {
                    count++;
                }*/
            });
        }
       
        return count;
    };
    const result = Referance.reduce((acc, item) => {
        if(timeFilter === "All Accounts" || timeFilter === "Spot"){
            const now = new Date();
            now.setHours(0, 0, 0, 0);

            const itemDate = new Date(item.date);
            itemDate.setHours(0, 0, 0, 0);

            
            acc.totalAmount += parseFloat(item.earned.replace(',', '.'));
            acc.friendIDs.add(item.friendID);

            if(SelectButton ==="Today"){
                if (itemDate.getTime() === now.getTime()) {
                    acc.TodayAmount += parseFloat(item.earned.replace(',', '.'));
                    acc.count.add(item.friendID);
                }
            }
            if(SelectButton ==="Yesterday"){
                now.setDate(now.getDate() - 1);
                if (itemDate.getTime() === now.getTime()) {
                    acc.TodayAmount += parseFloat(item.earned.replace(',', '.'));
                    acc.count.add(item.friendID);
                }
            }
            if(SelectButton ==="This Week"){
                now.setDate(now.getDate() - 7);
                if (itemDate.getTime() >= now.getTime()) {
                    acc.TodayAmount += parseFloat(item.earned.replace(',', '.'));
                    acc.count.add(item.friendID);
                }
            }
            if(SelectButton ==="This Month"){
                now.setMonth(now.getMonth() - 1);
                if (itemDate.getTime() >= now.getTime()) {
                    acc.TodayAmount += parseFloat(item.earned.replace(',', '.'));
                    acc.count.add(item.friendID);
                }
            }
        }
        
        return acc;
    }, { count: new Set(), TodayAmount: 0 , totalAmount: 0 , friendIDs: new Set()}); 

    const handleCopyAddress = async (Type) => {
        try {
            if (Type === 'ID') await navigator.clipboard.writeText(ReferanceCode);
            if (Type === 'Link') await navigator.clipboard.writeText(`http://game-bit.com?Refferal=${ReferanceCode}`);
            
           
        } catch (error) {
            console.error('Failed to copy address:', error);
        }
    };

    return (
        <div className="Referance-Panel">
            <div className='Referance-Panel-UP'>
                <img src="RefencaPanel.png" alt="" />
            </div>
            <div className="Referance-header">
                
                <div className='Referance-left'>
                    <h1>{t('Refer Friends')}</h1>
                    <p>{t('Invite a Friend')}</p>
                </div>
                <div className='Referance-Right'>
                    <div className='Referance-Right-Content'>
                        <div className='Referance-Right-Name'>{t('Default Referral')}</div>
                        <div className='Referance-Right-Comision'>
                            <span>
                                <h3 >{t('You Receive')}</h3>
                                <h2>{receive}%</h2>
                            </span>
                            <span>
                                <h3>{t('Friends Receive')}</h3>
                                <h2>{FriendsReceive}%</h2>
                            </span>
                        </div>
                        <div className='Referance-Right-Text'>
                            <label>{t('Referral ID')}</label>
                            <span>
                            {ReferanceCode}
                                <FaCopy onClick={() => handleCopyAddress('ID')}/>
                            </span>
                        </div>
                        <div className='Referance-Right-Text'>
                            <label>{t('Referral Link')}</label>
                            <span>
                                https://game-bit.com/?Refferal={ReferanceCode}
                                <FaCopy onClick={() => handleCopyAddress('Link')}/>
                            </span>
                        </div>
                        { 
                            /*
                            <div className='Referance-Right-Button'>
                                <div>Invite Friends</div>
                                <div><FaQrcode /> </div>
                            </div>
                            */
                        }
                    </div>
                </div>
            </div>
            <div className='Referance-Dashboard-Panel'>
                <div className='Referance-Dashboard-Panel-Name'>
                    <MdDashboard />
                    <label>{t('Dashboard')}</label>
                </div>
                <div className="Referance-Dashboard-select-Panel">
                    <div className='Referance-Dashboard-select'>
                        <select onChange={handleTimeFilterChange}>
                            <option value="All Accounts">{t('All Accounts')}</option>
                            <option value="Spot">{t('Spot')}</option>
                            <option value="Futuress">{t('Futuress')}</option>
                            <option value="Mining">{t('Mining')}</option>
                        </select>
                    </div>
                    <div className="Referance-Dashboard-select-Buttons">
                        <button onClick={() => setSelectButton('Today')} className={SelectButton === 'Today' ? 'Referance-Dashboard-select-Button-Active' : 'Referance-Dashboard-select-Button-inActive'}>{t('Today')}</button>
                        <button onClick={() => setSelectButton('Yesterday')} className={SelectButton === 'Yesterday' ? 'Referance-Dashboard-select-Button-Active' : 'Referance-Dashboard-select-Button-inActive'}>{t('Yesterday')}</button>
                        <button onClick={() => setSelectButton('This Week')} className={SelectButton === 'This Week' ? 'Referance-Dashboard-select-Button-Active' : 'Referance-Dashboard-select-Button-inActive'}>{t('This Week')}</button>
                        <button onClick={() => setSelectButton('This Month')} className={SelectButton === 'This Month' ? 'Referance-Dashboard-select-Button-Active' : 'Referance-Dashboard-select-Button-inActive'}>{t('This Month')}</button>
                    </div>
                </div>
                <div className='Referance-Dashboard-Content'>
                    <div className='Referance-Dashboard-Content-item'>
                       <label>{t('Your Earnings')} <span><PiWarningCircleFill /></span></label> 
                       <label>{parseFloat(result.totalAmount.toFixed(4)).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} GBIT</label> 
                       <label>+{parseFloat(result.TodayAmount.toFixed(4)).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} GBIT</label> 
                    </div>
                    <div className='Referance-Dashboard-Content-item'>
                       <label>{t('Friends Who')} <span><PiWarningCircleFill /></span></label> 
                       <label>{(timeFilter === "All Accounts"||timeFilter === "Spot") ? result.friendIDs.size:0} </label> 
                       <label>+{result.count.size}</label> 
                    </div>
                    <div className='Referance-Dashboard-Content-item'>
                       <label>{t('Friends')} <span><PiWarningCircleFill /></span></label> 
                       <label>{(timeFilter === "All Accounts"||timeFilter === "Spot") ? Object.keys(RefenceUsers).length:0}</label> 
                       <label>+{countTodaysDates()}</label> 
                    </div>
                </div>
                <div className='Referance-Dashboard-Label'>
                    <p>{t('Our system')}</p>
                    <p>{t('Statement')}</p>
                </div>
            </div>
            <div className='Referance-Referrals-Panel'>
                <div className='Referance-Dashboard-Panel'>
                    <div className='Referance-Dashboard-Panel-Name'>
                        <FaUsers  />
                        <label>{t('Referrals')}</label>
                    </div>
                    <div className="Referance-Dashboard-select-Panel">          
                        <div className="Referance-Dashboard-select-Buttons">
                            <button onClick={() => setSelectReferralsButton('All Accounts')} className={SelectReferralsButton === 'All Accounts' ? 'Referance-Dashboard-select-Button-Active' : 'Referance-Dashboard-select-Button-inActive'}>{t('All Accounts')}</button>
                            <button onClick={() => setSelectReferralsButton('Spot')} className={SelectReferralsButton === 'Spot' ? 'Referance-Dashboard-select-Button-Active' : 'Referance-Dashboard-select-Button-inActive'}>{t('Spot')}</button>
                            <button onClick={() => setSelectReferralsButton('Futuress')} className={SelectReferralsButton === 'Futuress' ? 'Referance-Dashboard-select-Button-Active' : 'Referance-Dashboard-select-Button-inActive'}>{t('Futuress')}</button>
                            <button onClick={() => setSelectReferralsButton('Mining')} className={SelectReferralsButton === 'Mining' ? 'Referance-Dashboard-select-Button-Active' : 'Referance-Dashboard-select-Button-inActive'}>{t('Mining')}</button>
                        </div>
                    </div>
                    <ReferralsList List={(SelectReferralsButton === "All Accounts" || SelectReferralsButton === "Spot") ? Referance:[]}/>
                    <div className='Referance-Dashboard-Label'>
                        <p>{t('As-per-our')}</p>
                    </div>
                </div>
            </div>
            <div className='Referance-Rules'>
                <h1>{t('Referral Program')}</h1>
                <p>
                {t('If-a-user')}
                </p>
                <p>
                {t('Commission-rates')}<br/>
                {t('Referrers-can-benefit')}
                    
                </p>
                <ul>
                    <li>{t('If-the-held-XGC-amount-is')} &lt; {t('be 5')}</li>
                    <li>{t('If-the-held-XGC-amount-is')} {t('be 6')}</li>
                    <li>{t('If-the-held-XGC-amount-is')} {t('be 7')}</li>
                    <li>{t('If-the-held-XGC-amount-is')} &gt; {t('be 10')}</li>
                </ul>
                <p>
                {t('Commission Distribution')}<br/>
                {t('Spot commission')}
                </p>
                <p>
                {t('Validity-of-Commissions')}<br/>
                </p>
                <ul>
                    <li>{t('The Referrer')}</li>
                    <li>{t('For accuracy')}</li>
                </ul>
                <p>
                {t('The platform')}
                </p>
                <p>
                {t('GBIT-Coins-received')}
                </p>
                <p>
                {t('Important Reminder')}
                </p>
            </div>
      </div>    
    );
}

export default Referance;
