import React, { useState, useEffect, useMemo, useContext} from 'react';
import './BuySelList_.css';
import CoinIcon from '../CoinIcon/CoinIcon';
import { IoSearch } from "react-icons/io5";
import {FaSortDown, FaSortUp  } from "react-icons/fa6";
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import { useTranslation } from 'react-i18next';
function BuySelList() {
    const { t } = useTranslation();
    const {SelectedCrypto,setSelectedCrypto,cryptoData,setCryptoData} = useContext(Degiskenler);
    const [PairSort, setPairSort] = useState(0);
    const [PriceSort, setPriceSort] = useState(0);
    const [ChangeSort, setChangeSort] = useState(0);
    
    const selectedCryptoInfo = cryptoData.find(coin => coin.pair === SelectedCrypto);

    const [searchTerm, setSearchTerm] = useState('');
    const toggleSort = (Name) => {
        if(Name === 'Pair')
        {
            setPairSort((current) => (current + 1) % 3);
            setChangeSort(0);
            setPriceSort(0);
        }
        if(Name === 'Price')
        {
            setPriceSort((current) => (current + 1) % 3);
            setChangeSort(0);
            setPairSort(0);
        }
        if(Name === 'Change')
        {
            setChangeSort((current) => (current + 1) % 3);
            setPairSort(0);
            setPriceSort(0);
        }

    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    const sortedAndFilteredData = useMemo(() => {
        const filtered = cryptoData.filter(data => 
            data.pair.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const sorted = filtered.sort((a, b) => {
            if (PairSort !== 0) {
                return PairSort === 1 ? a.pair.localeCompare(b.pair) : b.pair.localeCompare(a.pair);
            } else if (PriceSort !== 0) {
                return PriceSort === 1 ? parseFloat(a.price) - parseFloat(b.price) : parseFloat(b.price) - parseFloat(a.price);
            } else if (ChangeSort !== 0) {
                return ChangeSort === 1 ? parseFloat(a.changePercent) - parseFloat(b.changePercent) : parseFloat(b.changePercent) - parseFloat(a.changePercent);
            }
            return 0;
        });
        return sorted;
    }, [cryptoData, searchTerm, PairSort, PriceSort, ChangeSort]);
    useEffect(() => {
        const symbols = [
            'btcusdt',
            'ethusdt',
            'bnbusdt',
            'xrpusdt',
            'dogeusdt',
            'adausdt',
            'trxusdt',
            'shibusdt',
            'dotusdt',
            'bchusdt',
            'linkusdt',
            'ltcusdt',
            'nearusdt',
            'maticusdt',
            'uniusdt',
            'pepeusdt',
            'arbusdt',
            'flokiusdt',
            'xlmusdt',
            'bttusdt',
            'filusdt',
            'atomusdt',
            'mkrusdt',
            'vetusdt',
            'ftmusdt',
            'manausdt'



        ];
        const streams = symbols.map(symbol => `${symbol}@ticker`);
        const wsUrl = `wss://stream.binance.com:9443/stream?streams=${streams.join('/')}`;
        const ws = new WebSocket(wsUrl);
    
        ws.onopen = () => {
          console.log('WebSocket connection established.');
        };
    
        ws.onmessage = (event) => {
          const json = JSON.parse(event.data);
          const { s: symbol, c: lastPrice, p:priceChange,  P: priceChangePercent } = json.data;
          //console.log(symbol," ",lastPrice," ",priceChangePercent);
          setCryptoData(currentData => 
            currentData.map(item => 
                (item.pair+"USDT").toLowerCase() === symbol.toLowerCase() ? 
              { ...item, price: lastPrice, change:priceChange, changePercent: priceChangePercent } : item
            )
          );
        };
    
        ws.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
    
        return () => {
          ws.close();
          console.log('WebSocket connection closed.');
        };
    }, []);
   
  
  return (
    <div className="BuySelList">

        <div className='BuySelList-Search'>
            <IoSearch className='BuySelList-Icon' />
            <input type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
        </div>
        <div className='BuySelList-CoinList-Headers'>
            <div className="BuySelList-CoinList-Header" onClick={() => toggleSort('Pair')}>
                {t('Pair')}
                <span className='BuySelList-CoinList-Header-Arrow' >
                    <div><FaSortUp className={ PairSort === 1 ? 'sort-icon-inactive' : PairSort === 2 ? 'sort-icon-active' : 'sort-icon-active'}/></div>
                    <div><FaSortDown className={PairSort === 1 ? 'sort-icon-active' : PairSort === 2 ? 'sort-icon-inactive' : 'sort-icon-active'}/></div>
                </span>
            </div>
            <div className="BuySelList-CoinList-Header2" onClick={() => toggleSort('Price')}>
                {t('Price')}
                <span className='BuySelList-CoinList-Header-Arrow' >
                    <div><FaSortUp className={ PriceSort === 1 ? 'sort-icon-inactive' : PriceSort === 2 ? 'sort-icon-active' : 'sort-icon-active'}/></div>
                    <div><FaSortDown className={PriceSort === 1 ? 'sort-icon-active' : PriceSort === 2 ? 'sort-icon-inactive' : 'sort-icon-active'}/></div>
                </span>
            </div>
            <div className="BuySelList-CoinList-Header3"onClick={() => toggleSort('Change')}>
                {t('Change')}
                <span className='BuySelList-CoinList-Header-Arrow' >
                    <div><FaSortUp className={ ChangeSort === 1 ? 'sort-icon-inactive' : ChangeSort === 2 ? 'sort-icon-active' : 'sort-icon-active'}/></div>
                    <div><FaSortDown className={ChangeSort === 1 ? 'sort-icon-active' : ChangeSort === 2 ? 'sort-icon-inactive' : 'sort-icon-active'}/></div>
                </span>
            </div>
        </div>
        <div className='BuySelList-CoinList'>
            <table>
            <tbody>
                {sortedAndFilteredData.map((data, index) => (
                <tr key={index} className='BuySelList-CoinList-item'>
                    <td onClick={() => setSelectedCrypto(data.pair)}>
                        <div className='BuySelList-CoinList-Name'>
                            <div className='BuySelList-CoinList-img'><CoinIcon CoinID_={data.pair} CoinIcon_={true} CoinName_= {false}/></div> 
                            {data.pair}
                            <span>/USDT</span>
                        </div>
                    </td>
                    <td>{parseFloat(data.price).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})}</td>
                    <td style={{color: parseFloat(data.changePercent) >= 0 ? '#0ECB81' : 'red'}}>{parseFloat(data.changePercent) >= 0 ? `+${parseFloat(data.changePercent).toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}%` : `${parseFloat(data.changePercent).toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                        })}%`}&nbsp;
                    </td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>
        <div className='BuySelList-Info'>

            <div className='BuySelList-Info-Coin-img'>
                <CoinIcon CoinID_={SelectedCrypto} CoinIcon_={true} CoinName_= {true}/>
                <span>{t('Marketopen')}<div className="live-dot"></div></span>
            </div> 

            <div className='BuySelList-Info-Coin'>
                <label>{SelectedCrypto} / USDT</label>
                <span>{t('Spot')} . {t('Kripto')}</span>
            </div>
            <div className='BuySelList-Info-Price'>
                <span><strong>{parseFloat(selectedCryptoInfo.price).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})}</strong>   <label>USDT</label></span>&nbsp;
                <span style={{color: parseFloat(selectedCryptoInfo.change) >= 0 ? '#0ECB81' : 'red'}}>{parseFloat(selectedCryptoInfo.change) >= 0 ? `+${parseFloat(selectedCryptoInfo.change).toLocaleString(navigator.language, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                    })}` : `${parseFloat(selectedCryptoInfo.change).toLocaleString(navigator.language, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                    })}`}&nbsp;
                </span>
                <span style={{color: parseFloat(selectedCryptoInfo.changePercent) >= 0 ? '#0ECB81' : 'red'}}>{parseFloat(selectedCryptoInfo.changePercent) >= 0 ? `+${parseFloat(selectedCryptoInfo.changePercent).toLocaleString(navigator.language, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                    })}%` : `${parseFloat(selectedCryptoInfo.changePercent).toLocaleString(navigator.language, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                    })}%`}&nbsp;
                </span>
                    
            </div>    
           
        </div>
    </div>
  );
}

export default BuySelList;
