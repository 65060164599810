import React, { useState, useContext,useEffect } from 'react';
import CoinIcon from '../CoinIcon/CoinIcon';
import Cookies from 'js-cookie';
import { waveform } from 'ldrs'
import './Wallet_.css';
import {FaSortDown, FaSortUp  } from "react-icons/fa6";
import DashboardBalance from '../../components/DashboardBalance/DashboardBalance';
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function Wallet({ setPanel }) {
  const {t} = useTranslation();
  const location = useLocation();
  const [Loader, setLoader] = useState(false);
  const {BackHome,refreshToken, Wallet, setWallet, setSelectedCoin,setSelectedCrypto,setPNL} = useContext(Degiskenler);
  const [openPanelIndex, setOpenPanelIndex] = useState(null); // Açık panelin index'ini tutar
  const [searchTerm, setSearchTerm] = useState('');

  
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredTransactions = Wallet.filter(Wallet =>
    Wallet.coin.toLowerCase().includes(searchTerm.toLowerCase()) ||
    Wallet.Balance.toLowerCase().includes(searchTerm.toLowerCase()) ||
    Wallet.Available.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortTransactions = (Wallet) => {
      if (CoinSort !== 0) {
        Wallet.sort((a, b) => {
          let comparison = b.coin.localeCompare(a.coin);
          if (CoinSort === 2) comparison *= -1; // Reverse for descending
          return comparison;
        });
      } else if (NameSort !== 0) {
        Wallet.sort((a, b) => {
          let comparison = b.Name.localeCompare(a.Name);
          if (NameSort === 2) comparison *= -1;
          return comparison;
        });
      } else if (BalanceSort !== 0) {
        Wallet.sort((a, b) => {
          let comparison = parseFloat(a.Balance) - parseFloat(b.Balance);
          if (BalanceSort === 2) comparison *= -1;
          return comparison;
        });
      } else if (PriceSort !== 0) {
        Wallet.sort((a, b) => {
          let comparison = parseFloat(a.Price) - parseFloat(b.Price);
          if (PriceSort === 2) comparison *= -1;
          return comparison;
        });
      }
      return Wallet; // Return the sorted array
  };
  const renderTableRows = () => {
    const sortedTransactions = sortTransactions([...filteredTransactions]);
    
    return sortedTransactions.length > 0 ? (
      sortedTransactions.map((Wallet, index) => (
        
        <React.Fragment key={`${Wallet.coin}-${index}`}>
          
          <tr key={`${Wallet.coin}-${index}`} onClick={() => setOpenPanelIndex(openPanelIndex === index ? null : index)}>
            <td className='WalletCoinList-Coin'><CoinIcon CoinID_={Wallet.coin} CoinIcon_={true} CoinName_= {false} /> <span>{Wallet.coin}</span></td>
            <td className='WalletCoinList-CoinName'><CoinIcon CoinID_={Wallet.coin} CoinIcon_={false} CoinName_= {true} /></td>
            <td className='WalletCoinList-Balance'>{Loader && <l-waveform size="20" stroke="2.0" speed="1" color="#ccc"></l-waveform>}{!Loader && parseFloat(Wallet.Balance).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})}</td>
            <td className='WalletCoinList-Price'>{Loader && <l-waveform size="20" stroke="2.0" speed="1" color="#ccc"></l-waveform>} {!Loader && <span>$ {parseFloat(Wallet.Price).toLocaleString()}</span> } </td>
            <td className='WalletCoinList-Action'>
              <div onClick={() => {Wallet.Deposit && setPanel('Deposit');setSelectedCoin(Wallet.coin);setSelectedCrypto(Wallet.coin);;}} className={Wallet.Deposit ? 'WalletCoinList-Action-Button active' : 'WalletCoinList-Action-Button'}>{t('Deposit')}</div>
              <div onClick={() => {Wallet.Withdraw && setPanel('Withdraw');setSelectedCoin(Wallet.coin);setSelectedCrypto(Wallet.coin);;}} className={Wallet.Withdraw ? 'WalletCoinList-Action-Button active' : 'WalletCoinList-Action-Button'}>{t('Withdraw')}</div>
              <div onClick={() => {Wallet.Trade && setPanel('BuySel'); setSelectedCoin(Wallet.coin);setSelectedCrypto(Wallet.coin);;}} className={Wallet.Trade ? 'WalletCoinList-Action-Button active' : 'WalletCoinList-Action-Button'}>{t('Trade')}</div>
            </td>
          </tr> 
          {openPanelIndex === index && (
                  <tr key={index}> 
                      <td colSpan="4" className='WalletCoinList-Action2'>
                        <div onClick={() => {setPanel('Deposit');setSelectedCoin(Wallet.coin);setSelectedCrypto(Wallet.coin);}} className={Wallet.Deposit ? 'WalletCoinList-Action2-Button active' : 'WalletCoinList-Action2-Button'}>{t('Deposit')}</div>
                        <div onClick={() => {setPanel('Withdraw');setSelectedCoin(Wallet.coin);setSelectedCrypto(Wallet.coin);;}} className={Wallet.Withdraw ? 'WalletCoinList-Action2-Button active' : 'WalletCoinList-Action2-Button'}>{t('Withdraw')}</div>
                        <div onClick={() => {setPanel('BuySel'); setSelectedCoin(Wallet.coin);setSelectedCrypto(Wallet.coin);;}} className={Wallet.Trade ? 'WalletCoinList-Action2-Button active' : 'WalletCoinList-Action2-Button'}>{t('Trade')}</div>
                    </td>
                  </tr>
              )}
        </React.Fragment>
      ))
    ) : (
      <div className="no-data">{t('No data available')} </div>
    );
    
  };

  const [CoinSort, setCoinSort] = useState(0);
  const [NameSort, setNameSort] = useState(0);
  const [BalanceSort, setBalanceSort] = useState(0);
  const [PriceSort, setPriceSort] = useState(0);
  
  const toggleSort = (Name) => {
      if(Name === 'Coin')
      {
          setCoinSort((current) => (current + 1) % 3);
          setNameSort(0);
          setBalanceSort(0);
          setPriceSort(0);
      }
      if(Name === 'Name')
      {
          setNameSort((current) => (current + 1) % 3);
          setCoinSort(0);
          setBalanceSort(0);
          setPriceSort(0);
      }
      if(Name === 'Balance')
      {
          setBalanceSort((current) => (current + 1) % 3);
          setCoinSort(0);
          setNameSort(0);
          setPriceSort(0);
      }
      if(Name === 'Price')
      {
          setPriceSort((current) => (current + 1) % 3);
          setCoinSort(0);
          setNameSort(0);
          setBalanceSort(0);
      }
  };
  
  useEffect(() => {
    const fetchWalletData = async () => {
      setLoader(true);
      let Status= await refreshToken();
      if(Status){
        const token = Cookies.get('accessToken');
        if (token) {
          try {
            console.log("Wallet istek");
            const response = await fetch('https://game-bit.com/api/Users/CheckWallet', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
      
            if (!response.ok) {
                
              BackHome();
              console.log(`HTTP error! status: ${response.status} , Message: ${response.statusText}`);
              return;
            }
      
            const data = await response.json();
            setPNL({ today: data.today, pnl: data.pnl });
            setWallet(currentWallet => {
              return currentWallet.map(walletItem => {
                  const apiItem = data.wallet.find(item => item.symbol === walletItem.coin);
                  if (apiItem) {
                      return {
                          ...walletItem,
                          Balance: apiItem.balance,
                          Price: apiItem.price
                      };
                  }
                  return walletItem; // Eşleşme yoksa mevcut değeri döndür
              });
          });
      
            console.log('Wallet Data:', data);
        } catch (error) {
          BackHome();
          console.log('Error fetching wallet data:', error);
        }
        }
      }
      setLoader(false);
    };
    fetchWalletData();
    setPriceSort(2);
}, [location,BackHome, refreshToken,setWallet, setPNL]);

waveform.register()
  return (
      <div className='Wallet-Panel'>
          <DashboardBalance/>
          <div className="WalletCoinList-activities">
              <div className="WalletCoinList-header">  
              <div className="WalletCoinList-title">{t('Balance')}</div>
              <div className="WalletCoinList-controls">
                  <input type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
                  <button className='WalletCoinList-Button'>🔍</button>
              </div>
              </div>
          
              <div className='WalletCoinList-table-content'>
                  <table>
                      <thead className="WalletCoinList-table-header">
                          <tr>
                              <th onClick={() => toggleSort('Coin')}>
                                  <div className="WalletCoinList-th-flex">
                                  {t('Coin')}
                                      <span className='WalletCoinList-table-header-Arrow' >
                                          <div><FaSortUp className={ CoinSort === 1 ? 'sort-icon-inactive' : CoinSort === 2 ? 'sort-icon-active' : 'sort-icon-active'}/></div>
                                          <div><FaSortDown className={CoinSort === 1 ? 'sort-icon-active' : CoinSort === 2 ? 'sort-icon-inactive' : 'sort-icon-active'}/></div>
                                      </span>
                                  </div>
                              </th>
                              <th onClick={() => toggleSort('Name')}>
                                  <div className="WalletCoinList-th-flex">
                                  {t('Name')}
                                      <span className='WalletCoinList-table-header-Arrow' >
                                          <div><FaSortUp className={ NameSort === 1 ? 'sort-icon-inactive' : NameSort === 2 ? 'sort-icon-active' : 'sort-icon-active'}/></div>
                                          <div><FaSortDown className={NameSort === 1 ? 'sort-icon-active' : NameSort === 2 ? 'sort-icon-inactive' : 'sort-icon-active'}/></div>
                                      </span>
                                  </div>
                                  
                              </th>
                              <th onClick={() => toggleSort('Balance')}>
                                  <div className="WalletCoinList-th-flex">
                                  {t('Tatal Balance')}
                                      <span className='WalletCoinList-table-header-Arrow' >
                                          <div><FaSortUp className={ BalanceSort === 1 ? 'sort-icon-inactive' : BalanceSort === 2 ? 'sort-icon-active' : 'sort-icon-active'}/></div>
                                          <div><FaSortDown className={BalanceSort === 1 ? 'sort-icon-active' : BalanceSort === 2 ? 'sort-icon-inactive' : 'sort-icon-active'}/></div>
                                      </span>
                                  </div>
                              </th>
                              <th onClick={() => toggleSort('Price')}>
                                  <div className="WalletCoinList-th-flex">
                                  {t('Price')}
                                      <span className='WalletCoinList-table-header-Arrow' >
                                          <div><FaSortUp className={ PriceSort === 1 ? 'sort-icon-inactive' : PriceSort === 2 ? 'sort-icon-active' : 'sort-icon-active'}/></div>
                                          <div><FaSortDown className={PriceSort === 1 ? 'sort-icon-active' : PriceSort === 2 ? 'sort-icon-inactive' : 'sort-icon-active'}/></div>
                                      </span>
                                  </div>
                              </th>
                              <th>{t('Balance')}{t('Action')}</th>
                          </tr >
                      </thead>
                      <tbody>
                      {renderTableRows()}
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  );
}

export default Wallet;
