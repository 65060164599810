import React, {useEffect,useState, useRef, useContext} from 'react';
import './BuySel_.css';
import Cookies from 'js-cookie';
import { createChart } from 'lightweight-charts';
import { HiRefresh } from "react-icons/hi";

import Select, { components }  from 'react-select';
import { Degiskenler } from '../../Context/GlobalDegiskenler';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const BuySel = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const {refreshToken, BackHome,SelectedCrypto,Wallet, setWallet} = useContext(Degiskenler);
    const GBITBalance = Wallet.find(coin => coin.coin === 'GBIT');

    const [Grafik, setGrafik] = useState("Defoult");
    const [isTimeOpen, setIsTimeOpen] = useState(false);
    const [isChartOpen, setIsChartOpen] = useState(false);
    const [SelectTime, setSelectTime] = useState("4h");
    const [CustomTime, setCustomTime] = useState("1d");
    const [SelectChart, setSelectChart] = useState("Area");
    const [CustomChart, setCustomChart] = useState("Area");
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const seriesRef = useRef(null);

    const validTimes = ['1m', '10m', '4h'];
    const validChart = ['Bars', 'Candles', 'Area'];


    const [CoinBalance, setCoinBalance] = useState(0);
    const [CoinBalancePrice, setCoinBalancePrice] = useState(0);
    const [CoinMarketPrice, setCoinMarketPrice] = useState(1);
    const [CoinTotalBuy, setCoinTotalBuy] = useState("0.0005");
    const [CoinAmountBuy, setCoinAmountBuy] = useState(0);
    const [insufficient, setinsufficient] = useState(false);
    const [percentage, setPercentage] = useState(0);

    const sliderRef = useRef(null);
    const labelRef = useRef(null); 
    


    const labelSellRef = useRef(null); 
    const sliderSellRef = useRef(null);
    const [percentageSell, setPercentageSell] = useState(0);
    const [CoinTotalSell, setCoinTotalSell] = useState("0");
    const [CoinAmountSell, setCoinAmountSell] = useState('0');
    const [MaxSell, setMaxSell] = useState(0);

    const [ErorLog, setErorLog] = useState("");

    const fetchHistoricalData = async () => {
        const res = await fetch(`https://api.binance.com/api/v3/klines?symbol=${SelectedCrypto}USDT&interval=${SelectTime}&limit=1000`);
        const data = await res.json();
        if(SelectChart === 'Candles' || SelectChart === 'Bars'){
            return data.map(kline => ({
                time: kline[0] / 1000,  // Binance, zaman damgasını milisaniye cinsinden verir
                
                open: parseFloat(kline[1]),
                high: parseFloat(kline[2]),
                low: parseFloat(kline[3]),
                close: parseFloat(kline[4]),
            }));  
        }
        if(SelectChart === 'Hollow candles'){
            return data.map(kline => {
                const open = parseFloat(kline[1]);
                const close = parseFloat(kline[4]);
                const isUpCandle = close > open;
                return {
                    time: kline[0] / 1000,
                    open: open,
                    high: parseFloat(kline[2]),
                    low: parseFloat(kline[3]),
                    close: close,
                    color: isUpCandle ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 82, 82, 1)', // Down-candles için dolu, up-candles için boş
                    borderColor: isUpCandle ? 'rgba(0, 150, 136, 1)' : 'rgba(255, 82, 82, 1)' // Kenarlık rengi
                };
            });
        }
        if(SelectChart === 'Line' || SelectChart === 'Area'){
            return data.map(kline => ({
                time: kline[0] / 1000,
                value: parseFloat(kline[4]) 
            }));  
        }
        if(SelectChart === 'Line with markers'){
            return data.map(kline => ({
                time: kline[0] / 1000,
                value: parseFloat(kline[4]),  // Genellikle kapanış fiyatı kullanılır
            }));
        }
       
        if(SelectChart === 'Histogram'){
            return data.map(kline => ({
                time: kline[0] / 1000,
                value: parseFloat(kline[5]) 
            }));  
        }
        if(SelectChart === 'HLC area'){
            return data.map(kline => {
                return {
                    time: kline[0] / 1000,
                    high: parseFloat(kline[2]),
                    low: parseFloat(kline[3]),
                    close: parseFloat(kline[4])
                };
            });
        }
        if(SelectChart === 'Baseline'){
            return data.map(kline => ({
                time: kline[0] / 1000,
                close: parseFloat(kline[4]),
                value: parseFloat(kline[4]) - parseFloat(kline[1])
            }));  
        }
    };
    useEffect(() => {
        try {
            if(Grafik ==="Defoult")
            {
            
                if (SelectedCrypto === "GBIT") {
                    // GBIT için özel veri seti oluştur
                    const data = Array(1000).fill().map((_, index) => ({
                        time: index,  // Zamanı artırmadan sadece index kullanarak sabit bir artış sağla
                        value: 0.0005  // Sabit değer
                    }));
            
                    if (chartContainerRef.current && !chartRef.current) {
                        const chart = createChart(chartContainerRef.current, {
                            width: chartContainerRef.current.clientWidth,
                            height: chartContainerRef.current.clientHeight,
                            layout: {
                                background: { color: 'transparent' },
                                textColor: '#ffffff'
                            },
                            grid: {
                                vertLines: { color: 'rgba(40, 40, 40, 0.5)' },
                                horzLines: { color: 'rgba(40, 40, 40, 0.5)' }
                            },
                            priceScale: { borderVisible: false },
                            timeScale: {
                                borderVisible: false,
                                timeVisible: true,  // Zaman bilgisini göster
                                secondsVisible: false,
                            },
                            localization: {
                                priceFormatter: (p) => `${p.toFixed(4)}`,
                            }
                        });
                        if(SelectChart ==='Line'){
                            const lineSeries = chart.addLineSeries();
                            seriesRef.current = lineSeries;
                            chartRef.current = chart;
                            lineSeries.setData(data);
                        }
                        if(SelectChart ==='Line with markers'){
                            const lineSeries = chart.addLineSeries({
                                color: '#2196f3',
                                lineWidth: 2,
                            });
                            seriesRef.current = lineSeries;
                            chartRef.current = chart;
                            lineSeries.setData(data);
                        }
                        if(SelectChart ==='Area'){
                            const areaSeries = chart.addAreaSeries({
                                topColor: 'rgba(33, 150, 243, 0.56)',
                                bottomColor: 'rgba(33, 150, 243, 0.04)',
                                lineColor: 'rgba(33, 150, 243, 1)',
                                lineWidth: 2,
                            });
                            seriesRef.current = areaSeries;
                            chartRef.current = chart;
                            areaSeries.setData(data);
                        }
                    }
            
                    const handleResize = () => {
                        chartRef.current.applyOptions({
                            width: chartContainerRef.current.clientWidth,
                            height: chartContainerRef.current.clientHeight
                        });
                    };
                    window.addEventListener('resize', handleResize);
                    fetchWalletData(SelectedCrypto);
                    return () => {
                        window.removeEventListener('resize', handleResize);
                        if (chartRef.current) {
                            chartRef.current.remove();
                            chartRef.current = null;
                        }
                    };
                }
                else{

                
                    if (chartContainerRef.current && !chartRef.current) {
                        const chart = createChart(chartContainerRef.current, {
                            layout: {
                                background: {color: 'transparent'},
                                textColor: '#ffffff'
                            },
                            grid: {
                                vertLines: {
                                    color: 'rgba(40, 40, 40, 0.5)',
                                },
                                horzLines: {
                                    color: 'rgba(40, 40, 40, 0.5)', 
                                },
                            },
                        
                            priceScale: {
                                borderVisible: false,
                            },
                            localization: {
                                priceFormatter: (p) => `${p.toFixed(2)}`,
                            },
                            timeScale: {
                                borderVisible: false,
                                timeVisible: true, // Zaman bilgisini göster
                                secondsVisible: false, // Saniye bilgisini gizle
                            }
                        });
                        
                        if(SelectChart ==='Candles')
                        {
                            const candleSeries = chart.addCandlestickSeries({
                                upColor: '#4bffb5',
                                downColor: '#ff4976',
                                borderVisible: false,
                                wickUpColor: '#4bffb5',
                                wickDownColor: '#ff4976',
                            });
                            seriesRef.current = candleSeries;
                            chartRef.current = chart;
                            fetchHistoricalData().then(data => {
                                candleSeries.setData(data);
                                if(data.length > 0) {
                                    const formattedPrice = typeof data[0].close === 'string' ? data[0].close.replace(',', '.') : data[0].close.toString();
                                    const precision = (formattedPrice.split('.')[1] || '').length;
                                    chart.applyOptions({
                                        localization: {
                                        priceFormatter: (p) => `${p.toFixed(precision > 2 ? precision : 2)}`,
                                        },
                                    });
                                }
                            });
                        }
                        if(SelectChart ==='Hollow candles'){
                            const candleSeries = chart.addCandlestickSeries({
                                upColor: 'rgba(255, 255, 255, 0)',  // Şeffaf, yani boş iç
                                downColor: 'rgba(255, 82, 82, 1)',  // Dolu iç
                                borderVisible: true,
                                borderColor: '#000000',  // Kenarlık için sabit bir renk
                                wickColor: '#000000',  // Fitil rengi
                            });
                            seriesRef.current = candleSeries;
                            chartRef.current = chart;
                            fetchHistoricalData().then(data => {
                                candleSeries.setData(data);
                                if(data.length > 0) {
                                    const formattedPrice = typeof data[0].close === 'string' ? data[0].close.replace(',', '.') : data[0].close.toString();
                                    const precision = (formattedPrice.split('.')[1] || '').length;
                                    chart.applyOptions({
                                        localization: {
                                            priceFormatter: (p) => `${p.toFixed(precision > 2 ? precision : 2)}`,                                    },
                                    });
                                }
                            });
                        }
                        if(SelectChart ==='Bars'){
                            const barSeries = chart.addBarSeries({
                                upColor: 'green',
                                downColor: 'red',
                                thinBars: true,
                            });
                            seriesRef.current = barSeries;
                            chartRef.current = chart;
                            fetchHistoricalData().then(data => {
                                barSeries.setData(data);
                                if(data.length > 0) {
                                    const formattedPrice = typeof data[0].close === 'string' ? data[0].close.replace(',', '.') : data[0].close.toString();
                                    const precision = (formattedPrice.split('.')[1] || '').length;
                                    chart.applyOptions({
                                        localization: {
                                            priceFormatter: (p) => `${p.toFixed(precision > 2 ? precision : 2)}`,                                    },
                                    });
                                }
                            });
                        }
                        if(SelectChart ==='Line'){
                            const lineSeries = chart.addLineSeries({
                                color: '#2196f3',
                                lineWidth: 2,
                            });
                            seriesRef.current = lineSeries;
                            chartRef.current = chart;
                            fetchHistoricalData().then(data => {
                                lineSeries.setData(data);
                                if(data.length > 0) {
                                    const formattedPrice = typeof data[0].value === 'string' ? data[0].value.replace(',', '.') : data[0].value.toString();
                                    const precision = (formattedPrice.split('.')[1] || '').length;
                                    chart.applyOptions({
                                        localization: {
                                            priceFormatter: (p) => `${p.toFixed(precision > 2 ? precision : 2)}`,                                    },
                                    });
                                }
                            });
                        }
                        if(SelectChart ==='Line with markers'){
                            const lineSeries = chart.addLineSeries({
                                color: '#2196f3',
                                lineWidth: 2,
                            });
                            seriesRef.current = lineSeries;
                            chartRef.current = chart;
                            fetchHistoricalData().then(data => {
                                lineSeries.setData(data);
                                // Markerları ayarla, örnek olarak veri setinin belirli noktalarına
                                const markers = data.filter((point, index) => index % 20 === 0).map(point => ({
                                    time: point.time,
                                    position: 'aboveBar',
                                    color: 'red',
                                    shape: 'circle',
                                    text: 'M'
                                }));
                                lineSeries.setMarkers(markers);
                                if(data.length > 0) {
                                    const formattedPrice = typeof data[0].value === 'string' ? data[0].value.replace(',', '.') : data[0].value.toString();
                                    const precision = (formattedPrice.split('.')[1] || '').length;
                                    chart.applyOptions({
                                        localization: {
                                            priceFormatter: (p) => `${p.toFixed(precision > 2 ? precision : 2)}`,                                    },
                                    });
                                }
                            });
                        }
                        if(SelectChart ==='Area'){
                            const areaSeries = chart.addAreaSeries({
                                topColor: 'rgba(33, 150, 243, 0.56)',
                                bottomColor: 'rgba(33, 150, 243, 0.04)',
                                lineColor: 'rgba(33, 150, 243, 1)',
                                lineWidth: 2,
                            });
                            seriesRef.current = areaSeries;
                            chartRef.current = chart;
                            fetchHistoricalData().then(data => {
                                areaSeries.setData(data);
                                if(data.length > 0) {
                                    const formattedPrice = typeof data[0].value === 'string' ? data[0].value.replace(',', '.') : data[0].value.toString();
                                    const precision = (formattedPrice.split('.')[1] || '').length;
                                    chart.applyOptions({
                                        localization: {
                                            priceFormatter: (p) => `${p.toFixed(precision > 2 ? precision : 2)}`,                                    },
                                    });
                                }
                            });
                        }
                        if(SelectChart ==='Histogram'){
                            const histogramSeries = chart.addHistogramSeries({
                                color: '#26a69a',
                                priceFormat: {
                                    type: 'volume',
                                },
                                priceScaleId: '',
                                scaleMargins: {
                                    top: 0.32,
                                    bottom: 0,
                                },
                            });
                            seriesRef.current = histogramSeries;
                            chartRef.current = chart;
                            fetchHistoricalData().then(data => {
                                histogramSeries.setData(data);
                                if(data.length > 0) {
                                    const formattedPrice = typeof data[0].value === 'string' ? data[0].value.replace(',', '.') : data[0].value.toString();
                                    const precision = (formattedPrice.split('.')[1] || '').length;
                                    chart.applyOptions({
                                        localization: {
                                            priceFormatter: (p) => `${p.toFixed(precision > 2 ? precision : 2)}`,                                    },
                                    });
                                }
                            });
                        }
                        if(SelectChart ==='HLC area'){
                            const hlcAreaSeries = chart.addAreaSeries({
                                topColor: 'rgba(66, 165, 245, 0.4)', // Şeffaflığı arttırarak görsel bir hafifleme sağlanır
                                bottomColor: 'rgba(25, 118, 210, 0)', // Alt kısmı tamamen şeffaf
                                lineColor: 'rgba(25, 118, 210, 1)', // Çizgi rengi
                                lineWidth: 2
                            });
                            seriesRef.current = hlcAreaSeries;
                            chartRef.current = chart;
                            fetchHistoricalData().then(data => {
                                const hlcData = data.map(kline => ({
                                    time: kline.time,
                                    value: (kline.high + kline.low + kline.close) / 3 // Ortalama bir değer kullanarak alan grafiği oluşturur
                                }));
                                hlcAreaSeries.setData(hlcData);
                                if(data.length > 0) {
                                    const formattedPrice = typeof data[0].close === 'string' ? data[0].close.replace(',', '.') : data[0].close.toString();
                                    const precision = (formattedPrice.split('.')[1] || '').length;
                                    chart.applyOptions({
                                        localization: {
                                            priceFormatter: (p) => `${p.toFixed(precision > 2 ? precision : 2)}`,                                    },
                                    });
                                }
                            });
                        }
                        if(SelectChart ==='Baseline'){
                            const baselineSeries = chart.addBaselineSeries({
                                baseValue: {
                                    type: 'price',
                                    price: 0,
                                },
                                topFillColor1: 'rgba(38, 166, 154, 0.28)',
                                topFillColor2: 'rgba(38, 166, 154, 0.05)',
                                topLineColor: 'rgba(38, 166, 154, 1)',
                                bottomFillColor1: 'rgba(255, 82, 82, 0.05)',
                                bottomFillColor2: 'rgba(255, 82, 82, 0.28)',
                                bottomLineColor: 'rgba(255, 82, 82, 1)',
                            });
                            seriesRef.current = baselineSeries;
                            chartRef.current = chart;
                            fetchHistoricalData().then(data => {
                                baselineSeries.setData(data);
                                if(data.length > 0) {
                                    const formattedPrice = typeof data[0].close === 'string' ? data[0].close.replace(',', '.') : data[0].close.toString();
                                    const precision = (formattedPrice.split('.')[1] || '').length;
                                    chart.applyOptions({
                                        localization: {
                                            priceFormatter: (p) => `${p.toFixed(precision > 2 ? precision : 2)}`,                                    },
                                    });
                                }
                            });
                        }
                    }
                    
                    const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${SelectedCrypto.toLowerCase()}usdt@kline_${SelectTime}`);
            
                    ws.onmessage = (event) => {
                        if (!chartRef.current || !seriesRef.current) {
                            console.log("Komponent yok edilmiş.");
                            return;
                        }
                        const message = JSON.parse(event.data);
                        if(SelectChart === 'Candles' || SelectChart === 'Bars')
                        {
                            const { k: { t, o, h, l, c } } = message;
                        
                            const newCandle = {
                                time: t / 1000,
                                open: parseFloat(o),
                                high: parseFloat(h),
                                low: parseFloat(l),
                                close: parseFloat(c),
                            };
                
                            seriesRef.current.update(newCandle);
                        }
                        if(SelectChart === 'Hollow candles'){
                            const { k: { t, o, h, l, c } } = message;
                            const open = parseFloat(o);
                            const close = parseFloat(c);
                            const isUpCandle = close > open;
                        
                            const newCandle = {
                                time: t / 1000,
                                open: open,
                                high: parseFloat(h),
                                low: parseFloat(l),
                                close: close,
                                color: isUpCandle ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 82, 82, 1)', // Dolu veya boş iç
                                borderColor: isUpCandle ? 'rgba(0, 150, 136, 1)' : 'rgba(255, 82, 82, 1)' // Kenarlık rengi
                            };
                        
                            seriesRef.current.update(newCandle);
                        }
                        if(SelectChart === 'Line' || SelectChart === 'Area'){
                                const { k: { t, c } } = message;

                                const newPoint = {
                                    time: t / 1000,
                                    value: parseFloat(c),
                                };
                            
                                seriesRef.current.update(newPoint);
                        }
                        if(SelectChart === 'Line with markers'){
                            const { k: { t, c } } = message;
                        
                            const newPoint = {
                                time: t / 1000,
                                value: parseFloat(c),
                            };
                        
                            seriesRef.current.update(newPoint);
                        
                            // Yeni bir marker eklemek isterseniz, örneğin her 20 veri noktasında bir
                            if (newPoint % 20 === 0) {
                                const newMarker = {
                                    time: newPoint.time,
                                    position: 'aboveBar',
                                    color: 'red',
                                    shape: 'circle',
                                    text: 'M'
                                };
                                seriesRef.current.setMarkers([newMarker]);
                            }
                        }
                    
                        if(SelectChart === 'Histogram'){
                            const { k: { t, v } } = message; 

                            const newPoint = {
                                time: t / 1000,
                                value: parseFloat(v),
                            };
                        
                            seriesRef.current.update(newPoint);
                        }
                        if(SelectChart === 'HLC area'){
                            const { k: { t, h, l, c } } = message;
                            const newPoint = {
                                time: t / 1000,
                                value: (parseFloat(h) + parseFloat(l) + parseFloat(c)) / 3
                            };
                            seriesRef.current.update(newPoint);
                        }
                        if(SelectChart === 'Baseline'){
                            const { k: { t, o, c } } = message;

                            const newPoint = {
                                time: t / 1000,
                                value: parseFloat(c) - parseFloat(o),
                            };
                        
                            seriesRef.current.update(newPoint);
                        }
                        
                    };
                    const handleResize = () => {
                        if (chartRef.current && chartContainerRef.current){
                            chartRef.current.applyOptions({
                                width: chartContainerRef.current.clientWidth,
                                height: chartContainerRef.current.clientHeight
                            });
                        }
                        
                    };
                    window.addEventListener('resize', handleResize);
                    fetchWalletData(SelectedCrypto);
                    return () => {
                        window.removeEventListener('resize', handleResize);
                        if (ws) {
                            ws.close();
                        }
            
                        if (chartRef.current) {
                            chartRef.current.remove();
                            chartRef.current = null;
                        }
                    };
                }
            
            }
        } catch (error) {
                console.log("Hata Cart knk");
        }
    }, [Grafik,SelectTime,SelectChart,SelectedCrypto, chartContainerRef, seriesRef, chartRef]);

    const DropdownIndicator = props => {
        return (
          <components.DropdownIndicator {...props}>
            <i style={{
                position:'absolute',
                fontSize: '10px',
             }}>▼</i>
          </components.DropdownIndicator>
        );
    };
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: 'white', // Seçeneklerin yazı rengi
            backgroundColor: state.isFocused ? 'rgba(255, 255, 255, 0.1)' : provided.backgroundColor,

            cursor: 'pointer',
            width: 'auto',
            
        }),
        control: styles => ({
            ...styles,
            width: isTimeOpen ? '150px' : '30px', 
            height: '30px',
            backgroundColor: '#1e232900',
            
            color: 'white',
            border:'none',
            cursor: 'pointer',
            minHeight: '30px',
            
        }),
        input: (styles) => ({
            ...styles,
            margin: 0,
            padding: '0px',
            width: '0px' ,height: '0px'

        }),
        container: (base) => ({
            ...base,
            color: 'white',
            width: '100%',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            display:'none'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            display:isTimeOpen ? 'block':'none',
            fontSize: '13px',
        }),

        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#292d34', // Açılan menünün arka plan rengi
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)',
            width: '150px',
            height:'100%'
        }),
        menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#292d34',
        padding: 0,
        fontSize: '13px',
        border: 'none',
        '::-webkit-scrollbar': {
            width: '4px',  // Scrollbar genişliği
        },
        '::-webkit-scrollbar-track': {
            background: '#f1f1f1'  // Scrollbar track rengi
        },
        '::-webkit-scrollbar-thumb': {
            background: '#888'  // Scrollbar thumb rengi
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: '#555'  // Scrollbar thumb üzerine gelindiğinde rengi
        }

        }),
        indicatorSeparator: () => ({
          
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            position:'absolute',
            color: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            height: '10px',
            marginTop:'-0px',
            marginLeft:'-23px'
        }),

        
    };
    const groupedOptions = [
        {
            label: 'Minutes',
            options: [
                { value: '1m', label: <strong>1 minute</strong> },
                { value: '5m', label: <strong>5 minute</strong> },
                { value: '15m', label: <strong>15 minutes</strong> },
                { value: '30m', label: <strong>30 minutes</strong> },
            ]
        },
        {
            label: 'Hours',
            options: [
                { value: '1h', label: <strong>1 hour</strong> },
                { value: '2h', label: <strong>2 hours</strong> },
                { value: '4h', label: <strong>4 hours</strong> },
                { value: '6h', label: <strong>6 hours</strong> },
                { value: '8h', label: <strong>8 hours</strong> },
                { value: '12h', label: <strong>12 hours</strong> },
            ]
        },
        {
            label: 'Days',
            options: [
                { value: '1d', label: <strong>1 day</strong> },
                { value: '3d', label: <strong>3 day</strong> },
                { value: '1w', label: <strong>1 week</strong> },
                { value: '1M', label: <strong>1 month</strong> },
            ]
        }
    ];
    const formatGroupLabel = data => (
        <div style={{ color: '#8c8e90', backgroundColor: '#292d34', padding: '5px 0px',fontSize: '12px', }}>
            <span>{data.label}</span>
        </div>
    );
    const TimeChange = selectedOption => {
        setSelectTime(selectedOption.value);
        setIsTimeOpen(false);
        if(!validTimes.includes(selectedOption.value))
        {
            setCustomTime(selectedOption.value);
        }
    };
    const customStylesChart = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: 'white', // Seçeneklerin yazı rengi
            backgroundColor: state.isFocused ? 'rgba(255, 255, 255, 0.1)' : provided.backgroundColor,

            cursor: 'pointer',
            width: 'auto',
            
        }),
        control: styles => ({
            ...styles,
            width: isChartOpen ? '180px' : '30px', 
            height: '30px',
            backgroundColor: '#1e232900',
            
            color: 'white',
            border:'none',
            cursor: 'pointer',
            minHeight: '30px',
            
        }),
        input: (styles) => ({
            ...styles,
            margin: 0,
            padding: '0px',
            width: '0px' ,height: '0px'

        }),
        container: (base) => ({
            ...base,
            color: 'white',
            width: '100%',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            display:'none'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            display:isChartOpen ? 'block':'none',
            fontSize: '13px',
        }),

        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#292d34', // Açılan menünün arka plan rengi
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)',
            width: '180px'
        }),
        menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#292d34',
        padding: 0,
        fontSize: '13px',
        border: 'none'

        }),
        indicatorSeparator: () => ({
          
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            position:'absolute',
            color: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            height: '10px',
            marginTop:'-0px',
            marginLeft:'-23px'
        }),
        group: (provided, state) => ({
            ...provided,
            borderBottom: '1px solid #ccc', // Grup altında çizgi
            padding: '5px 0', // Çizginin altında ve üstünde boşluk
        })
        
    };
    const groupedOptionsChart = [
        {
            options: [
                { 
                    value: 'Bars',
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center',justifyContent: 'flex-start'}}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" style={{ marginRight: 8 }}>
                            <path fill="currentColor" fillRule="evenodd" d="M19 6h-1v7h-3v1h3v8h1v-3h3v-1h-3V6ZM11 7h-1v13H7v1h3v2h1V10h3V9h-3V7Z"></path>
                          </svg>
                          <strong>Bars</strong>
                        </div>
                      )
                },
                { 
                    value: 'Candles',
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' ,justifyContent: 'flex-start'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor" fillRule="evenodd" style={{ marginRight: 8 }}>
                                <path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path>
                                <path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path>
                                <path d="M9 8v12h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5z"></path>
                                <path d="M10 4h1v3.5h-1zm0 16.5h1V24h-1z"></path>
                            </svg>

                          <strong>Candles</strong>
                        </div>
                      )
                },
                { 
                    value: 'Hollow candles',
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' ,justifyContent: 'flex-start'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor" fillRule="evenodd" style={{ marginRight: 8 }}>
                                <path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path>
                                <path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path>
                                <path d="M9 8v11h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5z"></path>
                                <path d="M10 4h1v5h-1zm0 14h1v5h-1zM8.5 9H10v1H8.5zM11 9h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11z"></path>
                            </svg>
                          <strong>Hollow candles</strong>
                        </div>
                      )
                },
              
            ]
        },
        {
            options: [
                { 
                    value: 'Line',
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' , justifyItems:'flex-start'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" style={{ marginRight: 8 }}>
                                <path fill="currentColor" fillRule="evenodd" d="m25.39 7.31-8.83 10.92-6.02-5.47-7.16 8.56-.76-.64 7.82-9.36 6 5.45L24.61 6.7l.78.62Z"></path>
                            </svg>
                          <strong>Line</strong>
                        </div>
                      )
                },
                { 
                    value: 'Line with markers',
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' , justifyItems:'flex-start'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor" fillRule="evenodd" style={{ marginRight: 8 }}>
                                <path d="m18.43 15.91 6.96-8.6-.78-.62-6.96 8.6a2.49 2.49 0 0 0-2.63.2l-2.21-2.02A2.5 2.5 0 0 0 10.5 10a2.5 2.5 0 1 0 1.73 4.3l2.12 1.92a2.5 2.5 0 1 0 4.08-.31ZM10.5 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm7.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                                <path d="M8.37 13.8c.17.3.4.54.68.74l-5.67 6.78-.76-.64 5.75-6.88Z"></path>
                            </svg>

                          <strong>Line with markers</strong>
                        </div>
                      )
                }
            ]
        },
        {
            options: [
                { 
                    value: 'Area',
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' , justifyItems:'flex-start'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" style={{ marginRight: 8 }}>
                                <path fill="currentColor" fillRule="evenodd" d="m25.35 5.35-9.5 9.5-.35.36-.35-.36-4.65-4.64-8.15 8.14-.7-.7 8.5-8.5.35-.36.35.36 4.65 4.64 9.15-9.14.7.7ZM2 21h1v1H2v-1Zm2-1H3v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1V9h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1H8v1H7v1H6v1H5v1H4v1Zm1 0v1H4v-1h1Zm1 0H5v-1h1v1Zm1 0v1H6v-1h1Zm0-1H6v-1h1v1Zm1 0H7v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1H8v1H7v1h1v1Zm1 0v1H8v-1h1Zm0-1H8v-1h1v1Zm1 0H9v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1h1v1Zm1 0v1h-1v-1h1Zm0-1v-1h-1v1h1Zm0 0v1h1v1h1v-1h-1v-1h-1Zm6 2v-1h1v1h-1Zm2 0v1h-1v-1h1Zm0-1h-1v-1h1v1Zm1 0h-1v1h1v1h1v-1h1v1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h1v1Zm1 0h-1v1h1v-1Zm0-1h1v1h-1v-1Zm0-1h1v-1h-1v1Zm0 0v1h-1v-1h1Zm-4 3v1h-1v-1h1Z"></path>
                            </svg>
                          <strong>Area</strong>
                        </div>
                      )
                },
                { 
                    value: 'HLC area',
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' , justifyItems:'flex-start'}} >
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor" fillRule="evenodd" style={{ marginRight: 8 }}>
                           <path d="M22 3h1v1h-1V3Zm0 2V4h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1V9h-1V8h-1V7h-1V6h-1V5h-1v1H9v1H8v1H7v1H6v1H5v1H4v1h1v1H4v1h1v-1h1v-1h1v-1h1v-1h1V9h1V8h1v1h1v1h1v1h1v1h1v1h1v-1h1v-1h1v-1h1V9h1V8h1V7h1V6h1V5h-1Zm-1 1V5h1v1h-1Zm-1 1V6h1v1h-1Zm-1 1V7h1v1h-1Zm-1 1V8h1v1h-1Zm-1 1V9h1v1h-1Zm-1 1v-1h1v1h-1Zm-1 0v-1h-1V9h-1V8h-1V7h-1V6h-1v1H9v1H8v1H7v1H6v1H5v1h1v-1h1v-1h1V9h1V8h1V7h1v1h1v1h1v1h1v1h1Zm0 0h1v1h-1v-1Zm.84 6.37 7.5-7-.68-.74-7.15 6.67-4.66-4.65-.33-.34-.36.32-5.5 5 .68.74 5.14-4.68 4.67 4.66.34.35.35-.33ZM6 23H5v1h1v-1Zm0-1H5v-1h1v1Zm1 0v1H6v-1h1Zm0-1H6v-1h1v1Zm1 0v1H7v-1h1Zm0-1H7v-1h1v1Zm1 0v1H8v-1h1Zm0-1H8v-1h1v1Zm1 0v1H9v-1h1Zm0-1H9v-1h1v1Zm1 0h-1v1h1v1h1v1h1v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1h1v1Zm0 0h1v1h-1v-1Zm2 2v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1Zm0 0v-1h-1v1h1Z"></path></svg>
                          <strong>HLC area</strong>
                        </div>
                      )
                },
                { 
                    value: 'Baseline',
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' , justifyItems:'flex-start'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" style={{ marginRight: 8 }}>
                                <path fill="currentColor" fillRule="evenodd" d="m10.49 7.55-.42.7-2.1 3.5.86.5 1.68-2.8 1.8 2.82.84-.54-2.23-3.5-.43-.68Zm12.32 4.72-.84-.54 2.61-4 .84.54-2.61 4Zm-5.3 6.3 1.2-1.84.84.54-1.63 2.5-.43.65-.41-.65-1.6-2.5.85-.54 1.17 1.85ZM4.96 16.75l.86.52-2.4 4-.86-.52 2.4-4ZM3 14v1h1v-1H3Zm2 0h1v1H5v-1Zm2 0v1h1v-1H7Zm2 0h1v1H9v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Z"></path>
                            </svg>
                          <strong>Baseline</strong>
                        </div>
                      )
                },
            ]
        }
    ];
    const ChartChange = selectedOption => {
        setSelectChart(selectedOption.value);
        setIsChartOpen(false);
        if(!validChart.includes(selectedOption.value)){
            setCustomChart(selectedOption.value);
        }
    };
   
       
    

    useEffect(() => {
        if(Grafik === "Tradingview"){
            if(SelectedCrypto !== "GBIT"){
                new window.TradingView.widget({
                    "autosize": true,
                    "width": '100%',
                    "height": '100%',
                    "symbol": `BINANCE:${SelectedCrypto}USDT`,
                    "interval": "H",
                    "timezone": "Etc/UTC",
                    "theme": "dark",
                    "style": "1",
                    "locale": "en",
                    "toolbar_bg": "#f1f3f6",
                    "enable_publishing": false,
                    "hide_top_toolbar": false,
                    "withdateranges": true,
                    "range": "12M",
                    "allow_symbol_change": false,
                    "container_id": "tradingview_chart",
                    "hide_side_toolbar": false,
                    "enabled_features": ["display_legend_on_all_charts","left_toolbar"],
                    "disabled_features": ["timeframes_toolbar","timezone_menu"],
                });
                fetchWalletData(SelectedCrypto);
            } 
        }
    }, [Grafik,SelectedCrypto]);
   


    const handleSliderChangeSell  = (event) => {
        try {
            const newPercentage = event.target.value;
            setPercentageSell(newPercentage);
            calculateXGCRequiredSell(newPercentage/100);
        } catch (error) {
            
        }
       
    };
    const handleSliderScroolSell  = () => {
        try {
            if (sliderSellRef.current && labelSellRef.current) {
                const slider = sliderSellRef.current;
                const label = labelSellRef.current;
                const thumbWidth = 25;
                const sliderWidth = slider.offsetWidth;
                const thumbLeft = (percentageSell / 100) * (sliderWidth - thumbWidth) + (thumbWidth / 2);
                
                label.style.left = `${thumbLeft - (label.offsetWidth / 2)}px`;
            }
        } catch (error) {
            
        }
        
    };
    const handleChangeAmountSell = (event) => {
        try {
            let { value } = event.target;
            const decimalSeparator = getDecimalSeparator();
            const regex = new RegExp(`^[0-9]*(${decimalSeparator})?[0-9]*$`);
        
            if (!regex.test(value)) {
            value = value.replace(new RegExp(`[^0-9${decimalSeparator}]`, 'g'), '');
            if (value.split(decimalSeparator).length > 2) {
                const parts = value.split(decimalSeparator);
                value = parts[0] + decimalSeparator + parts[1].replace(new RegExp(`${decimalSeparator}`, 'g'), '');
            }
            }

            setCoinAmountSell(value);
            calculateRequiredSell(value);
        } catch (error) {
            setCoinAmountSell(0);
            calculateRequiredSell(0);
        }
        
    };
    const calculateRequiredSell = (value) => {
        try {
            const amount = parseFloat(value.toString().replace(',', '.'));
            const totalUsdt = amount * 0.0005;
            let required = totalUsdt / CoinMarketPrice;
            setPercentageSell(((required/CoinBalance)*100).toFixed(3));
            setCoinTotalSell((required).toFixed(11));
        } catch (error) {
            setPercentageSell(0);
            setCoinTotalSell(0);
        }
        
    };
    const calculateXGCRequiredSell = (percentage_) => {
        try {
            const coinAmount = GBITBalance.Balance * percentage_;
            const totalUsdt = coinAmount * 0.0005;
            let requiredXgc = totalUsdt /CoinMarketPrice;
            const preciseRequiredXgc = requiredXgc.toFixed(11);
            setCoinTotalSell(preciseRequiredXgc);
            setCoinAmountSell(parseFloat(coinAmount).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20,useGrouping: false}));
        } catch (error) {
            setCoinTotalSell(0);
            setCoinAmountSell(0);
        }
       
    };
    const MaxSellAmount= () => {
        try {
            const totalUsdt = parseFloat(GBITBalance.Balance) * 0.0005;
            let result = parseFloat(totalUsdt /CoinMarketPrice);
            setMaxSell(result);
        } catch (error) {
            setMaxSell(0);
        }
        
    }


    const getDecimalSeparator = () => {
        const numberWithDecimalSeparator = 1.1;
        return Intl.NumberFormat(navigator.language)
          .formatToParts(numberWithDecimalSeparator)
          .find(part => part.type === 'decimal').value;
      };
    const handleSliderChange  = (event) => {
        try {
            const newPercentage = event.target.value;
            setPercentage(newPercentage);
            calculateXGCRequired(newPercentage/100);
        } catch (error) {
            setPercentage(0);
            calculateXGCRequired(0);
        }
       
    };
    const handleSliderScrool  = () => {
        if (sliderRef.current && labelRef.current) {
            const slider = sliderRef.current;
            const label = labelRef.current;
            const thumbWidth = 25;
            const sliderWidth = slider.offsetWidth;
            const thumbLeft = (percentage / 100) * (sliderWidth - thumbWidth) + (thumbWidth / 2);
            
            label.style.left = `${thumbLeft - (label.offsetWidth / 2)}px`;
        }
    };
    const handleChangeAmount = (event) => {
        try {
            let { value } = event.target;
            const decimalSeparator = getDecimalSeparator();
            const regex = new RegExp(`^[0-9]*(${decimalSeparator})?[0-9]*$`);
        
            if (!regex.test(value)) {
            value = value.replace(new RegExp(`[^0-9${decimalSeparator}]`, 'g'), '');
            if (value.split(decimalSeparator).length > 2) {
                const parts = value.split(decimalSeparator);
                value = parts[0] + decimalSeparator + parts[1].replace(new RegExp(`${decimalSeparator}`, 'g'), '');
            }
            }

            setCoinAmountBuy(value);
            calculateRequired(value);
        } catch (error) {
            setCoinAmountBuy(0);
            calculateRequired(0);
        }
        
    };
    const calculateRequired = (xgcAmount) => {
        try {
            const amount = parseFloat(xgcAmount.toString().replace(',', '.'));
            const totalUsdt = (amount) * 0.0005;
            let required = totalUsdt / CoinMarketPrice;
            setPercentage(((required/CoinBalance)*100).toFixed(3));
            setCoinTotalBuy((required).toFixed(11));
        } catch (error) {
            setPercentage(0);
            setCoinTotalBuy(0);
        }
        
    };
    const calculateXGCRequired = (percentage_) => {
        try {
            const coinAmount = CoinBalance * percentage_;
            const totalUsdt = coinAmount * CoinMarketPrice;
            let requiredXgc = totalUsdt / 0.0005;
            const preciseRequiredXgc = requiredXgc.toFixed(8);
            setCoinTotalBuy((requiredXgc * (0.0005 / CoinMarketPrice)).toFixed(11));
            setCoinAmountBuy(parseFloat(preciseRequiredXgc).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20,useGrouping: false}));
        } catch (error) {
            setCoinTotalBuy(0);
            setCoinAmountBuy(0);
        }
        
    };
    function MaxBuyAmount() {
        try {
            let result = parseFloat((CoinBalancePrice / 0.0005));
            const preciseresult = result.toFixed(8); // 8 basamak hassasiyet
            return parseFloat(preciseresult).toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 20
            });
        } catch (error) {
            return 0
        }
        
    }
    const fetchWalletData = async (CoinName) => {
        let Status= await refreshToken();
        if(Status){
            const token = Cookies.get('accessToken');
            const requestBody = {coin: (CoinName !== 'GBIT'? CoinName:'USDT')};
            try {
                const response = await fetch('https://game-bit.com/api/Users/CheckBalance', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(requestBody)
                });
        
                if (!response.ok) {
                    BackHome();
                    console.log(`HTTP error! status: ${response.status}`);
                    return;
                }
        
                const data = await response.json();
                setCoinBalance(data.balance);// AVBL
                setCoinMarketPrice(data.price);//Price
                setCoinBalancePrice(data.balancePrice);// AVBL
                setCoinAmountBuy((1).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20,useGrouping: false}).toString());
                setWallet(currentWallet => {
                    return currentWallet.map(coin => {
                        if (coin.coin === (CoinName !== 'GBIT'? CoinName:'USDT')) {
                            return { ...coin, Balance: data.balance, Price: data.balancePrice };
                        }
                        return coin;
                    });
                });
                console.log('CheckBalance Data:', data);
            } catch (error) {
                BackHome();
                console.error('Error fetching wallet data:', error);
            }
        }
    };
    const fetchBuy = async () => {
        let Status= await refreshToken();
        if(Status){
            const token = Cookies.get('accessToken');
            
            const requestBody = {
                coin: SelectedCrypto!=='GBIT'? SelectedCrypto:'USDT',
                amount:CoinAmountBuy,
                total:CoinTotalBuy
            };
            try {
                const response = await fetch('https://game-bit.com/api/Users/Buy', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(requestBody)
                });
        
                if (!response.ok) {
                    BackHome();
                    console.log(`HTTP error! status: ${response.status}`);
                    return;
                }
        
                const data = await response.json();
                if(data.info === "successful")
                {
                    setCoinBalance(data.balance);
                    setCoinMarketPrice(data.marketPrice);
                    setCoinBalancePrice(data.balancePrice);
                    calculateXGCRequired(0);
                    setPercentage(0);
                    setCoinAmountBuy((0).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20,useGrouping: false}).toString());
                    setWallet(currentWallet => {
                        return currentWallet.map(coin => {
                            if (coin.coin === (SelectedCrypto !== 'GBIT'? SelectedCrypto:'USDT')) {
                                return { ...coin, Balance: data.balance, Price: data.balancePrice };
                            }
                            if (coin.coin === "GBIT") {
                                return { ...coin, Balance: data.gbit, Price: data.gbitPrice };
                            }
                            return coin;
                        });
                    });
                }
                if(data.info === "insufficient"){
                    setCoinBalance(data.balance);
                    setCoinMarketPrice(data.marketPrice);
                    setCoinBalancePrice(data.balancePrice);
                    setErorLog("Insufficient Funds");
                }
                if(data.info === "price protection"){
                    setCoinBalance(data.balance);
                    setCoinMarketPrice(data.marketPrice);
                    setCoinBalancePrice(data.balancePrice);
                    setErorLog.log("Price Protection");
                }
                if(data.info === "eror"){
                    setErorLog.log("eror");
                }
                if(data.info === "coin not found"){
                    setErorLog.log("Coin not found");
                }
                console.log('Buy Data:', data);
            } catch (error) {
                BackHome();
                console.error('Error fetching wallet data:', error);
            }
        }
    };
    useEffect(() => {
        fetchWalletData(SelectedCrypto);
    }, [location]);
    useEffect(() => {
        
        const amount = parseFloat(CoinAmountBuy.toString().replace(',', '.'));
        if(amount>1){
            let Balance=CoinBalance;
            let TotalBuy=parseFloat(CoinTotalBuy.toString().replace(',', '.'));;
            if(Balance !== 0 && amount > 0){
                if(TotalBuy <= Balance){
                    setinsufficient(false);
                }
                else{
                    setinsufficient(true);
                }
            }
        }
    }, [CoinAmountBuy,CoinBalance]);
    useEffect(() => {
        if (CoinMarketPrice > 0) {
            calculateRequired(1);
            setPercentage(0);
            MaxSellAmount();
        }
    }, [CoinMarketPrice]);
    useEffect(() => {
        handleSliderScrool();
    }, [percentage]);
    useEffect(() => {
        handleSliderScroolSell();
    }, [percentageSell]);
    useEffect(() => {
        MaxSellAmount();
    }, [GBITBalance]);

    useEffect(() => {
        if (ErorLog !== "") {
          const timer = setTimeout(() => {
            setErorLog("");
          }, 5000);
          return () => clearTimeout(timer);
        }
      }, [ErorLog]);
  return (
    <div className="BuySel">
        {Grafik === "Tradingview" && <div className='Tradingview'>
            <div className='Grafik-selected-Tradingview'>
                    <div className='Grafik-selected-Item' onClick={() => setGrafik('Defoult')}>GameBit</div>
                    <div className='Grafik-selected-Item-Active' onClick={() => setGrafik('Tradingview')}>Tradingview</div>
                </div>
            <div id="tradingview_chart" />
            <div className='ButtomHide'></div>
        </div>}
        {Grafik === "Defoult" && <div className='Defoult'>
            <div className='GrafikTop'>
                <div className='Grafik-Time'>
                    <button className={SelectTime ==="1m"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectTime('1m');}}>1m</button>
                    <button className={SelectTime ==="15m"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectTime('15m')}}>15m</button>
                    <button className={SelectTime ==="4h"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectTime('4h')}}>4h</button>
                    <button className={SelectTime === CustomTime ?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectTime(CustomTime)}}>{CustomTime}</button>
                    <div className='Grafik-Selecet'>
                        <Select
                            
                            value="SELECTED"
                            components={{ DropdownIndicator }}
                            onChange={TimeChange}
                            options={groupedOptions}
                            formatGroupLabel={formatGroupLabel}
                            styles={customStyles}
                            isSearchable={false}
                            onMenuOpen={() => setIsTimeOpen(true)}
                            onMenuClose={() => setIsTimeOpen(false)} 
                            placeholder="SELECTED"
                        />
                    </div>
                </div>
                <div className='Grafik-reagent'>|</div>

                <div className='Chart'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" className={SelectChart ==="Bars"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectChart('Bars');}}>
                        <path fill="currentColor" fillRule="evenodd" d="M19 6h-1v7h-3v1h3v8h1v-3h3v-1h-3V6ZM11 7h-1v13H7v1h3v2h1V10h3V9h-3V7Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor" fillRule="evenodd" className={SelectChart ==="Candles"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectChart('Candles');}}>
                        <path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path>
                        <path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path>
                        <path d="M9 8v12h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5z"></path>
                        <path d="M10 4h1v3.5h-1zm0 16.5h1V24h-1z"></path>
                    </svg>                    
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" className={SelectChart ==="Area"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectChart('Area');}}>
                        <path fill="currentColor" fillRule="evenodd" d="m25.35 5.35-9.5 9.5-.35.36-.35-.36-4.65-4.64-8.15 8.14-.7-.7 8.5-8.5.35-.36.35.36 4.65 4.64 9.15-9.14.7.7ZM2 21h1v1H2v-1Zm2-1H3v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1V9h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1H8v1H7v1H6v1H5v1H4v1Zm1 0v1H4v-1h1Zm1 0H5v-1h1v1Zm1 0v1H6v-1h1Zm0-1H6v-1h1v1Zm1 0H7v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1H8v1H7v1h1v1Zm1 0v1H8v-1h1Zm0-1H8v-1h1v1Zm1 0H9v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1h1v1Zm1 0v1h-1v-1h1Zm0-1v-1h-1v1h1Zm0 0v1h1v1h1v-1h-1v-1h-1Zm6 2v-1h1v1h-1Zm2 0v1h-1v-1h1Zm0-1h-1v-1h1v1Zm1 0h-1v1h1v1h1v-1h1v1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h1v1Zm1 0h-1v1h1v-1Zm0-1h1v1h-1v-1Zm0-1h1v-1h-1v1Zm0 0v1h-1v-1h1Zm-4 3v1h-1v-1h1Z"></path>
                    </svg>  
                    {CustomChart === 'Hollow candles' && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor" fillRule="evenodd" className={SelectChart ==="Hollow candles"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectChart('Hollow candles');}}>
                                <path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path>
                                <path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path>
                                <path d="M9 8v11h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5z"></path>
                                <path d="M10 4h1v5h-1zm0 14h1v5h-1zM8.5 9H10v1H8.5zM11 9h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11z"></path>
                    </svg>}    
                    {CustomChart === 'Line' &&  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" className={SelectChart ==="Line"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectChart('Line');}}>
                                <path fill="currentColor" fillRule="evenodd" d="m25.39 7.31-8.83 10.92-6.02-5.47-7.16 8.56-.76-.64 7.82-9.36 6 5.45L24.61 6.7l.78.62Z"></path>
                    </svg>}  
                    {CustomChart === 'Line with markers' &&   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor" fillRule="evenodd" className={SelectChart ==="Line with markers"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectChart('Line with markers');}}>
                                <path d="m18.43 15.91 6.96-8.6-.78-.62-6.96 8.6a2.49 2.49 0 0 0-2.63.2l-2.21-2.02A2.5 2.5 0 0 0 10.5 10a2.5 2.5 0 1 0 1.73 4.3l2.12 1.92a2.5 2.5 0 1 0 4.08-.31ZM10.5 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm7.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path>
                                <path d="M8.37 13.8c.17.3.4.54.68.74l-5.67 6.78-.76-.64 5.75-6.88Z"></path>
                    </svg>}  
                    {CustomChart === 'HLC area' &&    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor" fillRule="evenodd" className={SelectChart ==="HLC area"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectChart('HLC area');}}>
                        <path d="M22 3h1v1h-1V3Zm0 2V4h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1V9h-1V8h-1V7h-1V6h-1V5h-1v1H9v1H8v1H7v1H6v1H5v1H4v1h1v1H4v1h1v-1h1v-1h1v-1h1v-1h1V9h1V8h1v1h1v1h1v1h1v1h1v1h1v-1h1v-1h1v-1h1V9h1V8h1V7h1V6h1V5h-1Zm-1 1V5h1v1h-1Zm-1 1V6h1v1h-1Zm-1 1V7h1v1h-1Zm-1 1V8h1v1h-1Zm-1 1V9h1v1h-1Zm-1 1v-1h1v1h-1Zm-1 0v-1h-1V9h-1V8h-1V7h-1V6h-1v1H9v1H8v1H7v1H6v1H5v1h1v-1h1v-1h1V9h1V8h1V7h1v1h1v1h1v1h1v1h1Zm0 0h1v1h-1v-1Zm.84 6.37 7.5-7-.68-.74-7.15 6.67-4.66-4.65-.33-.34-.36.32-5.5 5 .68.74 5.14-4.68 4.67 4.66.34.35.35-.33ZM6 23H5v1h1v-1Zm0-1H5v-1h1v1Zm1 0v1H6v-1h1Zm0-1H6v-1h1v1Zm1 0v1H7v-1h1Zm0-1H7v-1h1v1Zm1 0v1H8v-1h1Zm0-1H8v-1h1v1Zm1 0v1H9v-1h1Zm0-1H9v-1h1v1Zm1 0h-1v1h1v1h1v1h1v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1h1v1Zm0 0h1v1h-1v-1Zm2 2v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1Zm0 0v-1h-1v1h1Z"></path>
                    </svg>} 

                    {CustomChart === 'Baseline' &&   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" className={SelectChart ==="Baseline"?'Grafik-Time-Active':'Grafik-Time-Pasive'} onClick={() => {setSelectChart('Baseline');}}>
                                <path fill="currentColor" fillRule="evenodd" d="m10.49 7.55-.42.7-2.1 3.5.86.5 1.68-2.8 1.8 2.82.84-.54-2.23-3.5-.43-.68Zm12.32 4.72-.84-.54 2.61-4 .84.54-2.61 4Zm-5.3 6.3 1.2-1.84.84.54-1.63 2.5-.43.65-.41-.65-1.6-2.5.85-.54 1.17 1.85ZM4.96 16.75l.86.52-2.4 4-.86-.52 2.4-4ZM3 14v1h1v-1H3Zm2 0h1v1H5v-1Zm2 0v1h1v-1H7Zm2 0h1v1H9v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Z"></path>
                    </svg>}                   
                    <div className='Chart-Selecet'>
                        <Select                      
                            value="SELECTED"
                            components={{ DropdownIndicator }}
                            onChange={ChartChange}
                            options={groupedOptionsChart}
                            formatGroupLabel={formatGroupLabel}
                            styles={customStylesChart}
                            isSearchable={false}
                            onMenuOpen={() => setIsChartOpen(true)}
                            onMenuClose={() => setIsChartOpen(false)} 
                            placeholder="SELECTED"
                        />
                    </div>
                </div>
                <div className='Grafik-selected'>
                    <div className='Grafik-selected-Item-Active' onClick={() => setGrafik('Defoult')}>GameBit</div>
                    <div className='Grafik-selected-Item' onClick={() => setGrafik('Tradingview')}>Tradingview</div>
                </div>
            </div>
            
            <div ref={chartContainerRef} style={{ width: '100%', height: '96.9%' }}>
                <div className='Grafik-Logo'>GAMEBIT</div>
            </div>
        </div>}
        <div className='BuySel-Spot-Bar'>
            <label htmlFor="">{t('Spot')}</label>
            <label htmlFor="">{t('Cross')}</label>
            <label htmlFor="">{t('Isolated')}</label>
            <label htmlFor="">{t('Grid')}</label>
            <div className='Buysel-Maker'>{t('Fee')} 0.00%</div>
        </div>
        <div className='BuySel-Spot-LimitMarket'>
            <label htmlFor="">{t('Market')}</label>
            <label htmlFor="">{t('Limit')}</label>
            {ErorLog!=="" && <label className='BuySel-BuyButton-Info'><span>{t('Eror')}: </span>{ErorLog}</label>}
        </div>
        <div className='BuySel-Buttom'>
            <div className='BuySel-Buy'>
                <div className='BuySel-Available'><span>{t('Available')}</span> {parseFloat(CoinBalance.toFixed(11)).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} {SelectedCrypto !== 'GBIT'?SelectedCrypto:'USDT'}</div>
                <div className="BuySel-Buy-input-text">
                    <label htmlFor="amount">{t('Price')}</label>
                    <div className='BuySel-Price-Content'>
                        <HiRefresh className='BuySel-UpdatePrice' onClick={() => fetchWalletData(SelectedCrypto)}/>
                        <span>{`${CoinMarketPrice.toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} USDT`}</span>
                    </div>
                    
                </div>
                <div className={insufficient?"BuySel-Buy-input-group-Red":"BuySel-Buy-input-group"}>
                    <label htmlFor="price">{t('Amount')}</label>
                    <input
                        type="text"
                        value={CoinAmountBuy}
                        onChange={handleChangeAmount}
                        step="0.01"
                        min="0"
                    />
                    <span className="currency">GBIT</span>
                </div>
                <div className="BuySel-Buy-slider-container">
                    <input
                        ref={sliderRef}
                        type="range"
                        min="0"
                        max="100"
                        value={percentage}
                        className="BuySel-Buy-custom-slider"
                        onChange={handleSliderChange}
                        onMouseEnter={handleSliderScrool}
                    />
                    <div ref={labelRef} className="tooltip">
                        {percentage}%
                    </div>
                </div>
                <div className="field">
                    <div className="input-group">
                    <label htmlFor="total">{t('Total')}</label>
                    <input type="text" id="total" value={parseFloat(CoinTotalBuy).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} readOnly />
                    <span className="currency">{SelectedCrypto !== 'GBIT'?SelectedCrypto:'USDT'}</span>
                    </div>
                </div>
                <div className="info">
                    <p>{t('MaxBuy')} <span className="highlight">{MaxBuyAmount()} GBIT</span></p>
                </div>
                <button className="BuySel-Buy-buy-button" onClick={() => fetchBuy()}>{t('Buy')} GBIT</button>
            </div>
            <div className='BuySel-Sell'>
                <div className='BuySel-Available'><span>{t('Available')} </span>
                 {
                    GBITBalance && GBITBalance.Balance !== null && !isNaN(GBITBalance.Balance)
                    ? `${parseFloat(parseFloat(GBITBalance.Balance).toFixed(11)).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 20 })} GBIT`
                    : "0 GBIT"
                }
                </div>
                <div className="BuySel-Buy-input-text">
                    <label htmlFor="amount">{t('Price')}</label>
                    <div className='BuySel-Price-Content'>
                        <span>{parseFloat(0.0005).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} USDT</span>
                    </div>
                    
                </div>
                <div className="BuySel-Buy-input-group">
                    <label htmlFor="price">{t('Amount')}</label>
                    <input
                        type="text"
                        value={CoinTotalSell}
                        onChange={handleChangeAmountSell}
                        step="0.01"
                        min="0"
                    />
                    <span className="currency">{SelectedCrypto !== 'GBIT'?SelectedCrypto:'USDT'}</span>
                </div>
                <div className="BuySel-Sell-slider-container">
                    <input
                        ref={sliderSellRef}
                        type="range"
                        min="0"
                        max="100"
                        value={percentageSell}
                        className="BuySel-Sell-custom-slider"
                        onChange={handleSliderChangeSell}
                        onMouseEnter={handleSliderScroolSell}
                    />
                    <div ref={labelSellRef} className="tooltip">
                        {percentageSell}%
                    </div>
                </div>
                <div className="field">
                    <div className="input-group">
                    <label htmlFor="total">{t('Total')}</label>
                    <input type="text" id="total" value={CoinAmountSell.toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} readOnly />
                    <span className="currency">GBIT</span>
                    </div>
                </div>
                <div className="info">
                    <p>{t('MaxBuy')} <span className="highlight">{parseFloat(MaxSell.toFixed(11)).toLocaleString(navigator.language,{minimumFractionDigits: 2,maximumFractionDigits: 20})} {SelectedCrypto !== 'GBIT'?SelectedCrypto:'USDT'}</span></p>
                </div>
                <button className="BuySel-Sell-buy-button">{t('Sell')} GBIT</button>
            </div>
        </div>
        <div className='BuySelButtons'>
            
        </div>
    </div>
  );
}

export default BuySel;